import { useRouter } from 'next/router';

import { getFromLocalStorage, setLocalStorage } from 'lib/localStorage';

import {
  postArticleComment,
  postOfferComment,
  postThreadComment,
} from 'services/comment';

import BLOG from 'constants/blog';
import FORUM from 'constants/forum';
import OFFER from 'constants/offer';

const LOCAL_STORAGE_KEY = 'promobit.pending-comments';
/**
 * This is a huge limit for comments and comments characters.
 * No comment will probably be this big or lengthy, but here we
 * make sure pending comments won't ever break the `localStorage` limit.
 */
const PENDING_COMMENT_TEXT_CHAR_LIMIT = 10000;
const PENDING_COMMENTS_LIMIT = 20;
const POST_COMMENT_REQUEST_BY_DOMAIN = {
  article: postArticleComment,
  offer: postOfferComment,
  thread: postThreadComment,
};
const PAGE_ROUTES_WITH_COMMENTS_SECTION = [
  '/oferta/[offerSlug]',
  '/forum/[...threadOrCategoryParams]',
  '/blog/[articleSlug]',
];
const PAGE_ROUTES_WITH_COMMENTS_SECTION_DOMAINS = {
  '/oferta/[offerSlug]': OFFER.DOMAIN,
  '/forum/[...threadOrCategoryParams]': FORUM.THREAD.DOMAIN,
  '/blog/[articleSlug]': BLOG.ARTICLES.DOMAIN,
};

const isPageWithComments = (route) =>
  PAGE_ROUTES_WITH_COMMENTS_SECTION.includes(route);

const getDomainFromUrl = (route) =>
  PAGE_ROUTES_WITH_COMMENTS_SECTION_DOMAINS[route];

const getDomainIdFromUrl = (pathname) => pathname.match(/-([0-9]+)(\/?)$/)[1];

const getCommentsToSendWithoutFromCurrentPage = (pendingComments, router) => {
  const domain = getDomainFromUrl(router.pathname);
  const domainId = Number(getDomainIdFromUrl(router.asPath));

  return pendingComments.filter(
    (comment) => !(comment.domain === domain && comment.domainId === domainId)
  );
};

const usePendingComments = () => {
  const router = useRouter();

  const createPendingComment = async ({
    comment,
    domain,
    domainId,
    domainIdKey,
  }) => {
    const stored = JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEY) || '[]');
    const { commentText } = comment;

    if (commentText.length < PENDING_COMMENT_TEXT_CHAR_LIMIT) {
      /**
       * We limit the quantity of stored comments to don't occupy local storage
       * completely.
       */
      if (stored.length >= PENDING_COMMENTS_LIMIT) {
        stored.pop();
      }

      stored.push({ comment, domain, domainIdKey, domainId });
    } else {
      const { captureMessage } = await import('@sentry/nextjs');

      captureMessage(
        `Comment could not be locally saved as pending, bigger than ${PENDING_COMMENT_TEXT_CHAR_LIMIT}.`
      );
    }

    setLocalStorage(LOCAL_STORAGE_KEY, JSON.stringify(stored));
  };

  const deletePendingComments = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
  };

  const deletePendingCommentsFromDomain = (domain, domainId) => {
    const stored = JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEY) || '[]');
    const updated = stored.filter(
      (comment) => !(comment.domain === domain && comment.domainId === domainId)
    );

    if (updated.length === 0) {
      deletePendingComments();
      return;
    }

    setLocalStorage(LOCAL_STORAGE_KEY, JSON.stringify(updated));
  };

  const getAllPendingComments = () =>
    JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEY) || '[]');

  const getPendingCommentsFromDomain = (domain, domainId) => {
    const stored = JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEY) || '[]');

    return stored.filter(
      (comment) => comment.domain === domain && comment.domainId === domainId
    );
  };

  const sendPendingComments = async () => {
    const pendingComments = getAllPendingComments();

    if (pendingComments.length === 0) {
      return;
    }

    /**
     * We handle comments that are from the current page differently to
     * maintain the internal cache up-to-date and make some visual updates.
     */
    const commentsToSend = isPageWithComments(router.pathname)
      ? getCommentsToSendWithoutFromCurrentPage(pendingComments, router)
      : pendingComments;

    if (commentsToSend.length === 0) {
      return;
    }

    try {
      for (const pendingComment of commentsToSend) {
        const { comment, domain, domainId, domainIdKey } = pendingComment;
        const data = {
          ...comment,
          [domainIdKey]: domainId,
        };

        await POST_COMMENT_REQUEST_BY_DOMAIN[domain](data);

        deletePendingCommentsFromDomain(domain, domainId);
      }
    } catch (e) {
      const { captureException } = await import('@sentry/nextjs');

      captureException(e);
    }
  };

  return {
    createPendingComment,
    deletePendingComments,
    deletePendingCommentsFromDomain,
    getAllPendingComments,
    getPendingCommentsFromDomain,
    sendPendingComments,
  };
};

export default usePendingComments;
