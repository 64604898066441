// Supix is our own pixel service, here are some utils to register
// events, revoke permissions and parse information that should be
// sent to italic
import { getUtmsFromSession } from './sessionStorage';
import { parseToBool } from './string';
import { isBrowser, isDev, log as logger } from './utils';

import APP from 'constants/app';
import {
  SEARCH_RESULTS_SUPIX_CONTEXT,
  SEARCH_RESULTS_SUPIX_VERTICAL,
} from 'constants/searchResults';

const isSupixEnabled = parseToBool(process.env.NEXT_PUBLIC_SUPIX_ENABLED);
const isSupixDebugEnabled = parseToBool(
  process.env.NEXT_PUBLIC_SUPIX_DEBUG_ENABLED
);

const log = (...args) => logger('supix', ...args);

export const getSearchContext = (headers) => {
  const { referer } = headers;
  let refererValue = -1;

  if (referer === `${APP.URL}/`) {
    return 0;
  }

  if (referer) {
    SEARCH_RESULTS_SUPIX_CONTEXT.forEach((v, i) => {
      if (referer.includes(v)) {
        refererValue = i;
      }
    });
  }

  return refererValue;
};

export const getSearchVertical = (type) =>
  SEARCH_RESULTS_SUPIX_VERTICAL[type] || -1;

export const sendSupixEvent = async (key, data) => {
  if (!isBrowser()) {
    return;
  }

  const options = { ...data, ...getUtmsFromSession() };

  if (isSupixDebugEnabled) {
    log(`Event sent to endpoint "${key}" with data: `, options);
  }

  if (!isSupixEnabled) {
    return;
  }

  const optionsQs = Object.keys(options)
    .map((optionKey) => `${optionKey}=${options[optionKey]}`)
    .join('&');

  try {
    await fetch(`${process.env.NEXT_PUBLIC_SUPIX_URL}${key}/?${optionsQs}`, {
      mode: isDev() ? 'no-cors' : 'cors',
      credentials: 'include',
    });
  } catch (err) {
    const { captureException } = await import('@sentry/nextjs');

    captureException(err, {
      contexts: {
        data: {
          key,
          optionsQs,
          ...data,
        },
      },
    });
  }
};
