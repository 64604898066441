import { OPEN_MODAL_EVENT } from 'components/ModalRoot';

import { dispatch } from 'lib/eventManager';

const useModal = () => {
  const showModal = (component, props = {}) => {
    dispatch(OPEN_MODAL_EVENT, { component, props }, { saveEventEmit: true });
  };

  return { showModal };
};

export default useModal;
