const LOCAL_STORAGE = {
  // TODO: change to promobit.access-token
  ACCESS_TOKEN: 'apiToken',
  IS_POLICY_NUMBER_1_ACCEPTED: 'police.cookie_1',
  IS_POLICY_NUMBER_2_ACCEPTED: 'police.cookie_2',
  IS_POLICY_NUMBER_3_ACCEPTED: 'police.cookie_3',
  IS_POLICY_NUMBER_5_ACCEPTED: 'police.cookie_5',
  IS_SHOWING_LGPD_ALERT: 'police.show_modal',
  DEPRECATED_USER_PHOTO: 'me.photo',
  LOGGED_IN_USER: 'loggedInUser',
  // Promobit 2.0
  IS_BLACK_FRIDAY_BUTTON_ALREADY_CLICKED:
    'promobit.is-black-friday-button-already-clicked',
  IS_BLACK_FRIDAY_BUTTON_ALREADY_CLICKED_MOBILE:
    'promobit.is-black-friday-button-already-clicked-mobile',
  IS_DOWNLOAD_APP_DIALOG_ACTIVE:
    'promobit.is-download-app-cta-component-home-ab-test',
  IS_DARK_MODE_ACTIVE: 'promobit.is-dark-mode-active',
  IS_EXTENSION_ACTIVE: 'promobit_extensions',
  IS_SUPIX_COLLECTED: 'promobit.is-supix-collected-v2',
  IS_INTERNATIONAL_OFFERS_ACTIVE: 'promobit.is-international-offers-active',
  IS_WISHLIST_MAINTENANCE_ALREADY_INTERACTED:
    'promobit.is-wishlist-maintenance-already-interacted',
  SHOWED_WELCOME_NOTIFICATION: 'showedWelcomeNotification',
  WEBPUSH_HASH: 'webpush-hash',
};

export default LOCAL_STORAGE;
