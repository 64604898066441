/**
 * These values are also directly imported in tailwind theme. Unifying all in
 * the tailwind config isn't good because accessing in runtime adds around
 * 17kb zipped to the shared bundle.
 */
const SCREENS = {
  sm: '22.5rem',
  md: '48rem',
  lg: '64rem',
  xl: '80rem',
  '2xl': '96rem',
  '3xl': '120rem',
};

export default SCREENS;
