import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import { getFirstPathFromPathname } from 'lib/request';

import LINKS from 'constants/links';

const TopbarBottomDefaultNav = dynamic(
  () => import('./TopbarBottomDefaultNav')
);
const TopbarBottomCouponsNav = dynamic(
  () => import('./TopbarBottomCouponsNav')
);
const TopbarBottomForumNav = dynamic(() => import('./TopbarBottomForumNav'));

const NAVS_BY_PAGE = {
  [LINKS.COUPONS]: TopbarBottomCouponsNav,
  [LINKS.FORUM]: TopbarBottomForumNav,
  DEFAULT: TopbarBottomDefaultNav,
};

const TopbarBottomNav = ({ gaEventCategory }) => {
  const { pathname } = useRouter();

  const firstPath = getFirstPathFromPathname(pathname);
  const NavComponent = NAVS_BY_PAGE[firstPath] ?? NAVS_BY_PAGE.DEFAULT;

  return <NavComponent gaEventCategory={gaEventCategory} />;
};

TopbarBottomNav.propTypes = {
  gaEventCategory: PropTypes.string.isRequired,
};

export default TopbarBottomNav;
