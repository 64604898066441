const BLOG = {
  ARTICLES: {
    CLIENT_SIDE_LIMIT: 10,
    DOMAIN: 'article',
    FILTERS: {
      CATEGORY: 'CATEGORY',
      TAG: 'TAG',
    },
    MAIN_LIMIT: 3,
    SERVER_SIDE_LIMIT: 13,
  },
};

export default BLOG;
