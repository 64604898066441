export const SEARCH_RESULTS_TYPES = {
  GENERAL: {
    NAME: 'Todos os resultados',
    QUERY: 'total',
    PARAM: 'general',
    BUTTON_TEXT: null,
    GA_EVENT_ACTION_PREFIX: null,
    GENERAL_RESULTS_LIMIT: 0,
    SORT_OPTIONS: [],
  },
  ALIAS: {
    NAME: 'Alias',
    QUERY: 'offers',
    PARAM: 'alias',
    BUTTON_TEXT: null,
    GA_EVENT_ACTION_PREFIX: 'alias_offers',
    GENERAL_RESULTS_LIMIT: 0,
    SORT_OPTIONS: [
      { isDefaultOption: false, label: 'Nome', value: 'name' },
      { isDefaultOption: true, label: 'Mais recentes', value: 'latest' },
      { isDefaultOption: false, label: 'Mais antigas', value: 'oldest' },
    ],
  },
  ACTIVE_OFFERS: {
    NAME: 'Ofertas ativas',
    QUERY: 'offers',
    PARAM: 'ofertas-ativas',
    BUTTON_TEXT: 'Ver todas as ofertas',
    GA_EVENT_ACTION_PREFIX: 'active_offers',
    GENERAL_RESULTS_LIMIT: 5,
    SORT_OPTIONS: [
      { isDefaultOption: false, label: 'Nome', value: 'name' },
      { isDefaultOption: true, label: 'Relevância', value: 'relevance' },
      { isDefaultOption: false, label: 'Maior preço', value: 'highest_price' },
      { isDefaultOption: false, label: 'Menor preço', value: 'lowest_price' },
      { isDefaultOption: false, label: 'Mais curtidas', value: 'likes' },
      { isDefaultOption: false, label: 'Mais recentes', value: 'latest' },
      { isDefaultOption: false, label: 'Mais antigas', value: 'oldest' },
    ],
  },
  FINISHED_OFFERS: {
    NAME: 'Ofertas encerradas',
    QUERY: 'offers',
    PARAM: 'ofertas-encerradas',
    BUTTON_TEXT: 'Ver todas as ofertas',
    GA_EVENT_ACTION_PREFIX: 'finished_offers',
    GENERAL_RESULTS_LIMIT: 3,
    SORT_OPTIONS: [
      { isDefaultOption: false, label: 'Nome', value: 'name' },
      { isDefaultOption: true, label: 'Relevância', value: 'relevance' },
      { isDefaultOption: false, label: 'Maior preço', value: 'highest_price' },
      { isDefaultOption: false, label: 'Menor preço', value: 'lowest_price' },
      { isDefaultOption: false, label: 'Mais curtidas', value: 'likes' },
      { isDefaultOption: false, label: 'Mais recentes', value: 'latest' },
      { isDefaultOption: false, label: 'Mais antigas', value: 'oldest' },
    ],
  },
  COUPONS: {
    NAME: 'Cupons de Desconto',
    QUERY: 'coupons',
    PARAM: 'cupons',
    BUTTON_TEXT: null,
    GA_EVENT_ACTION_PREFIX: 'coupons',
    GENERAL_RESULTS_LIMIT: 3,
    SORT_OPTIONS: [
      { isDefaultOption: false, label: 'Nome', value: 'name' },
      { isDefaultOption: true, label: 'Mais recentes', value: 'latest' },
      { isDefaultOption: false, label: 'Mais antigas', value: 'oldest' },
    ],
  },
  THREADS: {
    NAME: 'Tópicos no fórum',
    QUERY: 'threads',
    PARAM: 'topicos',
    BUTTON_TEXT: 'Ver todos os tópicos',
    GA_EVENT_ACTION_PREFIX: 'topics',
    GENERAL_RESULTS_LIMIT: 3,
    SORT_OPTIONS: [
      { isDefaultOption: false, label: 'Nome', value: 'name' },
      { isDefaultOption: false, label: 'Mais comentados', value: 'comments' },
      { isDefaultOption: false, label: 'Mais curtidas', value: 'likes' },
      { isDefaultOption: true, label: 'Mais recentes', value: 'latest' },
      { isDefaultOption: false, label: 'Mais antigas', value: 'oldest' },
    ],
  },
  ARTICLES: {
    NAME: 'Artigos do blog',
    QUERY: 'articles',
    PARAM: 'artigos',
    BUTTON_TEXT: 'Ver todos os artigos',
    GA_EVENT_ACTION_PREFIX: 'articles',
    GENERAL_RESULTS_LIMIT: 3,
    SORT_OPTIONS: [
      { isDefaultOption: false, label: 'Nome', value: 'name' },
      { isDefaultOption: false, label: 'Mais comentados', value: 'comments' },
      { isDefaultOption: true, label: 'Mais recentes', value: 'latest' },
      { isDefaultOption: false, label: 'Mais antigas', value: 'oldest' },
    ],
  },
  USERS: {
    NAME: 'Membros da comunidade',
    QUERY: 'users',
    PARAM: 'membros',
    BUTTON_TEXT: 'Ver todos os usuários',
    GA_EVENT_ACTION_PREFIX: 'members',
    GENERAL_RESULTS_LIMIT: 3,
    SORT_OPTIONS: [
      { isDefaultOption: true, label: 'Nome', value: 'name' },
      { isDefaultOption: false, label: 'Maior nível', value: 'highest_level' },
      { isDefaultOption: false, label: 'Menor nível', value: 'lowest_level' },
    ],
  },
  STORES: {
    NAME: 'Lojas',
    QUERY: 'stores',
    PARAM: null,
    BUTTON_TEXT: null,
    GA_EVENT_ACTION_PREFIX: null,
    GENERAL_RESULTS_LIMIT: 5,
    SORT_OPTIONS: [],
  },
  SUBCATEGORIES: {
    NAME: 'Subcategorias relacionadas',
    QUERY: 'subcategories',
    TYPE: null,
    BUTTON_TEXT: null,
    GA_EVENT_ACTION_PREFIX: null,
    GENERAL_RESULTS_LIMIT: 5,
    SORT_OPTIONS: [],
  },
};

export const SEARCH_RESULTS_VALID_TYPE_PARAMS = [
  SEARCH_RESULTS_TYPES.GENERAL.PARAM,
  SEARCH_RESULTS_TYPES.ALIAS.PARAM,
  SEARCH_RESULTS_TYPES.ACTIVE_OFFERS.PARAM,
  SEARCH_RESULTS_TYPES.FINISHED_OFFERS.PARAM,
  SEARCH_RESULTS_TYPES.COUPONS.PARAM,
  SEARCH_RESULTS_TYPES.THREADS.PARAM,
  SEARCH_RESULTS_TYPES.ARTICLES.PARAM,
  SEARCH_RESULTS_TYPES.USERS.PARAM,
];

export const SEARCH_RESULTS_TYPE_PARAM_TO_TYPE = {
  [SEARCH_RESULTS_TYPES.GENERAL.PARAM]: SEARCH_RESULTS_TYPES.GENERAL,
  [SEARCH_RESULTS_TYPES.ALIAS.PARAM]: SEARCH_RESULTS_TYPES.ALIAS,
  [SEARCH_RESULTS_TYPES.ACTIVE_OFFERS.PARAM]:
    SEARCH_RESULTS_TYPES.ACTIVE_OFFERS,
  [SEARCH_RESULTS_TYPES.FINISHED_OFFERS.PARAM]:
    SEARCH_RESULTS_TYPES.FINISHED_OFFERS,
  [SEARCH_RESULTS_TYPES.COUPONS.PARAM]: SEARCH_RESULTS_TYPES.COUPONS,
  [SEARCH_RESULTS_TYPES.THREADS.PARAM]: SEARCH_RESULTS_TYPES.THREADS,
  [SEARCH_RESULTS_TYPES.ARTICLES.PARAM]: SEARCH_RESULTS_TYPES.ARTICLES,
  [SEARCH_RESULTS_TYPES.USERS.PARAM]: SEARCH_RESULTS_TYPES.USERS,
};

export const SEARCH_RESULTS_LEGACY_TYPE_PARAM_TO_TYPE = {
  ofertas: SEARCH_RESULTS_TYPES.ACTIVE_OFFERS,
  encerradas: SEARCH_RESULTS_TYPES.FINISHED_OFFERS,
  cupons: SEARCH_RESULTS_TYPES.COUPONS,
  forum: SEARCH_RESULTS_TYPES.THREADS,
  artigos: SEARCH_RESULTS_TYPES.ARTICLES,
  usuarios: SEARCH_RESULTS_TYPES.USERS,
};

export const SEARCH_RESULTS_TYPE_PARAM_TO_COUNT_KEY = {
  [SEARCH_RESULTS_TYPES.GENERAL.PARAM]: 'total',
  [SEARCH_RESULTS_TYPES.ACTIVE_OFFERS.PARAM]: 'activeOffers',
  [SEARCH_RESULTS_TYPES.FINISHED_OFFERS.PARAM]: 'finishedOffers',
  [SEARCH_RESULTS_TYPES.COUPONS.PARAM]: 'coupons',
  [SEARCH_RESULTS_TYPES.THREADS.PARAM]: 'threads',
  [SEARCH_RESULTS_TYPES.ARTICLES.PARAM]: 'articles',
  [SEARCH_RESULTS_TYPES.USERS.PARAM]: 'users',
};

export const SEARCH_RESULTS_TYPE_PARAM_TO_TYPE_SORT_OPTIONS = {
  [SEARCH_RESULTS_TYPES.ACTIVE_OFFERS.PARAM]:
    SEARCH_RESULTS_TYPES.ACTIVE_OFFERS.SORT_OPTIONS,
  [SEARCH_RESULTS_TYPES.FINISHED_OFFERS.PARAM]:
    SEARCH_RESULTS_TYPES.FINISHED_OFFERS.SORT_OPTIONS,
  [SEARCH_RESULTS_TYPES.COUPONS.PARAM]:
    SEARCH_RESULTS_TYPES.COUPONS.SORT_OPTIONS,
  [SEARCH_RESULTS_TYPES.THREADS.PARAM]:
    SEARCH_RESULTS_TYPES.THREADS.SORT_OPTIONS,
  [SEARCH_RESULTS_TYPES.ARTICLES.PARAM]:
    SEARCH_RESULTS_TYPES.ARTICLES.SORT_OPTIONS,
  [SEARCH_RESULTS_TYPES.USERS.PARAM]: SEARCH_RESULTS_TYPES.USERS.SORT_OPTIONS,
};

export const SEARCH_RESULTS_TYPE_PARAM_TO_TYPE_SORT_DEFAULT_VALUE = {
  [SEARCH_RESULTS_TYPES.ACTIVE_OFFERS.PARAM]:
    SEARCH_RESULTS_TYPES.ACTIVE_OFFERS.SORT_OPTIONS,
  [SEARCH_RESULTS_TYPES.FINISHED_OFFERS.PARAM]:
    SEARCH_RESULTS_TYPES.FINISHED_OFFERS.SORT_OPTIONS,
  [SEARCH_RESULTS_TYPES.COUPONS.PARAM]:
    SEARCH_RESULTS_TYPES.COUPONS.SORT_OPTIONS,
  [SEARCH_RESULTS_TYPES.THREADS.PARAM]:
    SEARCH_RESULTS_TYPES.THREADS.SORT_OPTIONS,
  [SEARCH_RESULTS_TYPES.ARTICLES.PARAM]:
    SEARCH_RESULTS_TYPES.ARTICLES.SORT_OPTIONS,
  [SEARCH_RESULTS_TYPES.USERS.PARAM]: SEARCH_RESULTS_TYPES.USERS.SORT_OPTIONS,
};

export const SEARCH_RESULTS_SUPIX_CONTEXT = [
  'oferta',
  'cupons',
  'forum',
  'blog',
];

export const SEARCH_RESULTS_SUPIX_VERTICAL = {
  [SEARCH_RESULTS_TYPES.GENERAL.PARAM]: 1,
  [SEARCH_RESULTS_TYPES.ALIAS.PARAM]: 1,
  [SEARCH_RESULTS_TYPES.ACTIVE_OFFERS.PARAM]: 1,
  [SEARCH_RESULTS_TYPES.FINISHED_OFFERS.PARAM]: 2,
  [SEARCH_RESULTS_TYPES.COUPONS.PARAM]: 3,
  [SEARCH_RESULTS_TYPES.THREADS.PARAM]: 4,
  [SEARCH_RESULTS_TYPES.ARTICLES.PARAM]: 6,
  [SEARCH_RESULTS_TYPES.USERS.PARAM]: 5,
};
