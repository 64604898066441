import PropTypes from 'prop-types';
import { createContext, useContext, useRef, useState } from 'react';

import { noop } from 'lib/utils';

const AutoCompleteContext = createContext({
  isListOpen: false,
  isTyping: false,
  setListOpen: noop,
  setTyping: noop,
  size: 'size1',
  searchInputValue: { current: '' },
});

function AutoCompleteProvider({ children, size }) {
  const searchInputValue = useRef('');
  const [isListOpen, setListOpen] = useState(false);
  const [isTyping, setTyping] = useState(false);

  return (
    <AutoCompleteContext.Provider
      value={{
        isListOpen,
        isTyping,
        setListOpen,
        setTyping,
        size,
        searchInputValue,
      }}
    >
      {children}
    </AutoCompleteContext.Provider>
  );
}

export const useAutoComplete = () => useContext(AutoCompleteContext);

AutoCompleteProvider.propTypes = {
  size: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default AutoCompleteProvider;
