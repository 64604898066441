import { toAbsoluteLink } from 'lib/links';
import placeholder from 'lib/placeholder';

import APP from './src/constants/app';
import MOBILE_APPS from './src/constants/mobileApp';
import SOCIAL_MEDIA from './src/constants/socialMedia';

// Default configs for SEO related <head> tags
const NEXT_SEO_CONFIG = {
  title: placeholder('seo.default.title'),
  description: placeholder('seo.default.description'),
  canonical: APP.URL,
  additionalMetaTags: [
    {
      name: 'viewport',
      content:
        'minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover',
    },
    {
      name: 'application-name',
      content: APP.NAME,
    },
    {
      name: 'apple-mobile-web-app-capable',
      content: 'yes',
    },
    {
      name: 'apple-mobile-web-app-status-bar-style',
      content: 'default',
    },
    {
      name: 'apple-mobile-web-app-title',
      content: APP.NAME,
    },
    {
      name: 'format-detection',
      content: 'telephone=no',
    },
    {
      name: 'mobile-web-app-capable',
      content: 'yes',
    },
    {
      name: 'theme-color',
      content: '#f4f4f4',
    },
    {
      name: 'google-play-app',
      content: `app-id=${MOBILE_APPS.ANDROID.APP_PACKAGE}`,
    },
    {
      property: 'al:android:package',
      content: MOBILE_APPS.ANDROID.APP_PACKAGE,
    },
    {
      property: 'al:android:app_name',
      content: MOBILE_APPS.APP_NAME,
    },
    {
      property: 'al:web:should_fallback',
      content: 'false',
    },
    {
      name: 'msapplication-config',
      content: `/dist/browserconfig.xml`,
    },
    {
      name: 'msapplication-TileColor',
      content: '#f4f4f4',
    },
    {
      name: 'msapplication-TileImage',
      content: `${APP.LOCAL_IMAGE_PATH}/icons/mstile-144x144.png`,
    },
    {
      name: 'msapplication-navbutton-color',
      content: '#f4f4f4',
    },
  ],
  openGraph: {
    url: APP.URL,
    type: 'website',
    title: placeholder('seo.default.og.title'),
    description: placeholder('seo.default.og.description'),
    site_name: APP.NAME,
    locale: APP.DEFAULT_LOCALE,
    images: [
      {
        url: toAbsoluteLink(
          `${APP.LOCAL_IMAGE_PATH}/general/logos/promobit-opengraph.png`
        ),
        width: '600',
        height: '600',
      },
    ],
  },
  twitter: {
    handle: SOCIAL_MEDIA.TWITTER.USER,
    site: SOCIAL_MEDIA.TWITTER.USER,
    cardType: 'summary',
  },
  facebook: {
    appId: SOCIAL_MEDIA.FACEBOOK.APP_ID,
  },
};

export default NEXT_SEO_CONFIG;
