// TODO: unify objs of STATUSES with STATUSES_BADGES
import { SORT_VALUES } from './sort';

const OFFER = {
  APPROVED_OFFER: 'Oferta aprovada',
  BADGES: {
    EXCLUSIVE: '⭐️ Exclusivo',
    FINISHED: 'Encerrada',
    FINISHED_OFFER: 'Oferta encerrada',
    LOWEST_PRICE: 'Menor preço',
    RECOMMENDED: 'Recomendada',
    UNVERIFIED: 'Oferta não verificada',
    VERIFIED: 'Oferta verificada',
  },
  DOMAIN: 'offer',
  FILTER_ACTION_TYPES: {
    PRICE_RANGE: 'PRICE_RANGE',
    REMOVE_PRICE_RANGE: 'REMOVE_PRICE_RANGE',
    REMOVE_STORE: 'REMOVE_STORE',
    REMOVE_VENUE: 'REMOVE_VENUE',
    RESET: 'RESET',
    STORES: 'STORES',
    VENUE: 'VENUE',
  },
  LAST_OFFERS_HASH: 'last-offers',
  MY_OFFERS_PAGINATION_LIMIT: 10,
  PAGE_TYPE: 'offer',
  PRICE_TYPES: {
    COUPON: 'COUPON',
    FREE: 'FREE',
    NORMAL: 'NORMAL',
    STARTING_AT: 'STARTING_AT',
  },
  REVIEWS: { DEFAULT_RATING_VALUE: 5 },
  SIDEBAR_FEATURED_OFFERS_LIMIT: 4,
  SORT_VALUES,
  STATUSES: {
    APPROVED: 'APPROVED',
    ARCHIVED: 'ARCHIVED',
    BLOCKED: 'BLOCKED',
    DUPLICATED: 'DUPLICATED',
    ETERNAL: 'ETERNAL',
    FINISHED: 'FINISHED',
    HIDDEN: 'HIDDEN',
    PRE_APPROVED: 'PRE_APPROVED',
    RECENTS: 'RECENTS',
    RESTRICTED: 'RESTRICTED',
    ROLLBACK: 'ROLLBACK',
    SCHEDULED: 'SCHEDULED',
    TOP_OFFER: 'TOP_OFFER',
    WAITING: 'WAITING',
  },
  STATUSES_BADGES: {
    APPROVED: {
      NAME: 'Ativa',
      BADGE_TYPE: 'neutral',
    },
    ARCHIVED: {
      NAME: 'Arquivada',
      BADGE_TYPE: 'neutral',
    },
    BLOCKED: {
      NAME: 'Bloqueada',
      BADGE_TYPE: 'error',
    },
    DUPLICATED: {
      NAME: 'Duplicada',
      BADGE_TYPE: 'error',
    },
    ETERNAL: {
      NAME: 'Eterna',
      BADGE_TYPE: 'highlight',
    },
    FINISHED: {
      NAME: 'Encerrada',
      BADGE_TYPE: 'neutral',
    },
    HIDDEN: {
      NAME: 'Oculta',
      BADGE_TYPE: 'neutral',
    },
    PRE_APPROVED: {
      NAME: 'Pré aprovada',
      BADGE_TYPE: 'neutral',
    },
    RESTRICTED: {
      NAME: 'Restrita',
      BADGE_TYPE: 'neutral',
    },
    RECENTS: {
      NAME: 'Recente',
      BADGE_TYPE: 'neutral',
    },
    ROLLBACK: {
      NAME: 'Rollback',
      BADGE_TYPE: 'error',
    },
    SCHEDULED: {
      NAME: 'Agendada',
      BADGE_TYPE: 'highlight',
    },
    TOP_OFFER: {
      NAME: 'Menor preço',
      BADGE_TYPE: 'highlight',
    },
    WAITING: {
      NAME: 'Aguardando aprovação',
      BADGE_TYPE: 'neutral',
    },
  },
  VENUES_VALUES: { INTERNATIONAL: 'international', NATIONAL: 'national' },
  VIEW_MODES: { GRID: 'GRID', LIST: 'LIST' },
  VISIBILITY_STATUS: {
    ALL: 'ALL',
    LOGGED_ONLY: 'LOGGED',
    UNLOGGED_ONLY: 'UNLOGGED',
  },
};

export default OFFER;
