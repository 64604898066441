import PropTypes from 'prop-types';
import { useRef } from 'react';
import { twJoin } from 'tailwind-merge';

import useKeyDown from 'hooks/useKeyDown';

import { useAutoComplete } from '../../providers/AutoCompleteProvider';

function Root({ children, className, full, onSend }) {
  const rootRef = useRef(null);
  const { setListOpen } = useAutoComplete();

  useKeyDown(
    ['Enter', 'NumpadEnter'],
    () => {
      const input = rootRef.current.querySelector('input');
      const hasSelectedByKeyboard = rootRef.current.querySelector(
        'div[aria-selected="true"]'
      );

      if (input.value.length === 0 && !hasSelectedByKeyboard) {
        return;
      }

      input.blur();

      if (!hasSelectedByKeyboard && onSend) {
        setListOpen(false);
        onSend(input.value);
      }
    },
    {
      targetRef: rootRef,
    }
  );

  return (
    <div
      ref={rootRef}
      className={twJoin('relative', className, full ? 'w-full' : 'w-max')}
    >
      {children}
    </div>
  );
}

Root.propTypes = {
  children: PropTypes.node.isRequired,
  full: PropTypes.bool.isRequired,
  onSend: PropTypes.func,
};

export default Root;
