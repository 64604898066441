import { useMemo, useReducer } from 'react';

const findWithHigherPriority = (stored) => {
  let higherPriority = null;

  for (const item of stored) {
    if (!higherPriority || higherPriority.priority > item.priority) {
      higherPriority = item;
    }
  }

  return higherPriority;
};

const reducer = (state, { action, data, key, priority = 1 }) => {
  if (action === 'enqueue') {
    return [...state, { data, key, priority }];
  }

  return state.filter((item) => item.key !== key);
};

const useQueue = () => {
  const [store, queuer] = useReducer(reducer, []);

  const active = useMemo(() => findWithHigherPriority(store)?.data, [store]);

  return { active, queuer };
};

export default useQueue;
