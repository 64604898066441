const MOBILE_APPS = {
  APP_NAME: 'Promobit',
  ANDROID: {
    APP_PACKAGE: 'br.com.promobit.app',
    DESCRIPTION: 'Baixar o aplicativo do Promobit para Android',
  },
  IOS: {
    DESCRIPTION: 'Baixar o aplicativo do Promobit para iOS',
  },
};

export default MOBILE_APPS;
