import router from 'next/router';

import { omit } from './object';
import { stripQs } from './request';

import LANDINGS from 'constants/landings';
import LINKS from 'constants/links';
import LOGIN from 'constants/login';
import NOTIFICATION from 'constants/notification';
import { SEARCH_RESULTS_TYPES } from 'constants/searchResults';

export const canonize = (url) => toAbsoluteLink(stripQs(sanitize(url)));

/**
 * Normally to know if a URL is safe we can use webAPIs like `URL.parse` and
 * `URL.canParse`, but in NodeJS these APIs are available only in latest
 * versions of runtime (v22 and v18 respectively). However, we cannot bump
 * bump NodeJS version of release environment due conflicts with old Promobit.
 */
const isURLSafe = (url) => {
  try {
    return !!new URL(url);
  } catch {
    return false;
  }
};

export const sanitize = (url) => {
  const duplicatedBaseURL = /(https?:\/\/[^\s/]+\/[^\s/]+\/[^\s/]+).*\1/;
  const cleanedUrl = url.replace(duplicatedBaseURL, '$1');

  const urlWithoutWhiteSpacesAndReplacementChar = cleanedUrl
    .replace(/%20/g, '-')
    .replace(/\s+/g, '-')
    // Some browsers don't handle this char very well
    .replace('�', '');
  const urlWithoutHashMark = toUrlWithoutHashMark(
    urlWithoutWhiteSpacesAndReplacementChar
  );
  const decodedUrl = decodeURIComponent(urlWithoutHashMark);

  const sanitized = decodedUrl
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/_/g, '-')
    .replace(/--+/g, '-');

  /**
   * This fallback is a fallback to avoid server issues when even after all
   * treatments the URL is not considered valid by browser.
   */
  if (!isURLSafe(sanitized)) {
    return LINKS.HOME;
  }

  return sanitized;
};

export const toAbsoluteLink = (path) =>
  `https://${process.env.NEXT_PUBLIC_SUBDOMAIN}.promobit.com.br${path}`;

export const toArticleCategoryLink = (slug) => `/blog/category/${slug}/`;

export const toArticleLink = (articleSlug, { scrollToComment = null } = {}) =>
  scrollToComment
    ? `/blog/${articleSlug}/#comment:${scrollToComment}`
    : `/blog/${articleSlug}/`;

export const toBlogCategoryLink = (categorySlug) =>
  `/blog/category/${categorySlug}/`;

export const toBlogTagLink = (tagSlug) => `/blog/tag/${tagSlug}/`;

export const toCategoryLink = (slug) => `/promocoes/${slug}/`;

export const toCleanedSearchResultsPage = (resultTypeParam, searchQuery) => {
  const query = {
    ...omit(router.query, ['type', 'sort', 'p', 'a', 'alias']),
    q: searchQuery,
  };

  if (resultTypeParam !== SEARCH_RESULTS_TYPES.GENERAL.PARAM) {
    query.type = resultTypeParam;
  }

  router.push({ pathname: LINKS.SEARCH, query });
};

export const toCouponStoreWithCouponIdLink = (couponId, couponSlug) =>
  couponId
    ? `/cupons/loja/${couponSlug}/#cupom:${couponId}`
    : `/cupons/loja/${couponSlug}/`;

export const toForumLink = ({ categorySlug = null, page = null }) => {
  const pageSlug = page && page !== 1 ? `/pagina/${page}/` : null;
  const basePath = '/forum';
  const basePathWithOptionalCategorySlug = categorySlug
    ? `${basePath}/${categorySlug}`
    : basePath;

  return pageSlug
    ? `${basePathWithOptionalCategorySlug}${pageSlug}`
    : `${basePathWithOptionalCategorySlug}/`;
};

// Make sure to use `string/isUrlValidForInternalUsage` before using this
// as it can leads to error otherwise. `isUrlValidForInternalUsage` is not
// encapsulated because we would need to resolve the cases that aren't internal
// URLs.
export const toInternalLink = (externalUrl = '') =>
  externalUrl.replace(/https:\/\/www\.promobit\.com\.br/, '').toLowerCase();

export const toLandingPageLink = (landingSlug, landingType) => {
  if (landingSlug === 'black-friday') {
    return '/black-friday/';
  }

  switch (landingType) {
    case LANDINGS.TYPES.SEASONAL:
      return `/promocoes-do-ano/${landingSlug}/`;
    case LANDINGS.TYPES.INSTITUTIONAL:
      return `/institucional/${landingSlug}/`;
    case LANDINGS.TYPES.BLACK_FRIDAY:
      return `/black-friday/${landingSlug}/`;
    default:
      return null;
  }
};

export const toLastCommentsLink = (slug = null, page = null) => {
  const pagination = page ? `pagina/${page}/` : null;
  const basePath = '/ultimos-comentarios/';
  const basePathWithOptionalSlug = slug ? `${basePath}${slug}/` : basePath;

  return pagination
    ? `${basePathWithOptionalSlug}${pagination}`
    : basePathWithOptionalSlug;
};

export const toMessagesLink = (messageId) =>
  `https://${process.env.NEXT_PUBLIC_SUBDOMAIN}.promobit.com.br/Central/mensagens/?conversation=${messageId}`;

export const toNotificationLink = (
  slug,
  origin,
  { couponIds, commentId } = { commentId: null, couponIds: null }
) => {
  const linkOptionToScrollToComment = commentId
    ? { scrollToComment: commentId }
    : { scrollToComment: null };

  switch (origin) {
    case NOTIFICATION.ORIGINS.OFFER:
      return toOfferLink(slug, linkOptionToScrollToComment);
    case NOTIFICATION.ORIGINS.THREAD:
      return toThreadLink(slug, linkOptionToScrollToComment);
    case NOTIFICATION.ORIGINS.ARTICLE:
      return toArticleLink(slug, linkOptionToScrollToComment);
    case NOTIFICATION.ORIGINS.COUPON:
      return toCouponStoreWithCouponIdLink(couponIds, slug);
    default:
      return null;
  }
};

export const toOfferLink = (slug, { scrollToComment = null } = {}) =>
  scrollToComment
    ? `/oferta/${slug}/#comment:${scrollToComment}`
    : `/oferta/${slug}/`;

export const toOfferRedirectLink = (offerId, qs = '') =>
  `https://${
    process.env.NEXT_PUBLIC_SUBDOMAIN
  }.promobit.com.br/Redirect/to/${offerId}${qs ? '?' : ''}${qs}`;

export const toUrlWithoutHashMark = (url) => url.split(/#\w+/)[0];

export const toUserProfileLink = (userName) => `/usuario/@${userName}/`;

export const toPromobitCouponsWithCouponIdLink = (couponId) =>
  `/cupons/promobit/#cupom:${couponId}`;

export const toRedirectAfterLoginLink = (currPathname, redirectPathname) =>
  `${currPathname}?${LOGIN.AFTER_LOGIN_REDIRECT_QS}=${redirectPathname}`;

export const toRedirectCouponStoreLink = (couponId) =>
  `https://${process.env.NEXT_PUBLIC_SUBDOMAIN}.promobit.com.br/Redirect/cupom/${couponId}`;

export const toSearchResultsLink = (
  searchQuery,
  { type, sort, page } = { type: null, sort: null, page: null }
) => {
  const options = { type, sort, page };
  const basePathWithQuery = `/buscar/?q=${encodeURIComponent(searchQuery)}`;
  const optionsKeys = ['type', 'sort', 'page'];
  const linkStr = Object.values(options)
    .reduce(
      (acc, option, index) =>
        option ? `${acc}&${optionsKeys[index]}=${option}` : acc,
      [basePathWithQuery]
    )
    .toString();

  return linkStr;
};

export const toSearchResultsWithCleanedLink = (options = {}) => {
  const optionsQs = [];

  Object.keys(options).forEach((optionKey) => {
    if (options[optionKey]) {
      optionsQs.push(`${optionKey}=${options[optionKey]}`);
    }
  });

  const concatenatedOptionsQs = optionsQs.join('&');

  return `${LINKS.SEARCH}?${concatenatedOptionsQs}`;
};

export const toSearchResultsWithPaginationPage = (page) =>
  router.push({
    pathname: LINKS.SEARCH,
    query: { ...router.query, p: page },
  });

export const toSearchResultsWithSortPage = (sortValue) =>
  router.push({
    pathname: LINKS.SEARCH,
    // Resets pagination on sort change
    query: { ...omit(router.query, ['p']), sort: sortValue },
  });

export const toSubcategoryLink = (slug) => `/promocoes/${slug}/s/`;

export const toStoreCouponsLink = (slug) =>
  slug ? `/cupons/loja/${slug}/` : '/cupons/';

export const toStoreOffersLink = (slug) =>
  slug ? `/promocoes/loja/${slug}/` : '/lojas/';

export const toThreadLink = (slug, { scrollToComment = null } = {}) =>
  scrollToComment
    ? `/forum/${slug}/#comment:${scrollToComment}`
    : `/forum/${slug}/`;

export const toThreadCategoryLink = (categorySlug) =>
  categorySlug ? `/forum/${categorySlug}/` : '/forum/';
