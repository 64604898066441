import PropTypes from 'prop-types';
import { createContext, useContext } from 'react';

import useSingleton from 'hooks/useSingleton';

import { setSessionStorage } from 'lib/sessionStorage';
import { browserOnly } from 'lib/utils';

const UTM_PARAMS_TO_BE_SAVED_IN_SESSION_STORAGE = [
  'utm_campaign',
  'utm_content',
  'utm_medium',
  'utm_source',
];

const UtmContext = createContext({ isUtmLoaded: false });

const UtmProvider = ({ children }) => {
  const isUtmLoaded = useSingleton(
    browserOnly(() => {
      const urlSearchParams = new URLSearchParams(window.location.search);

      urlSearchParams.forEach((value, key) => {
        if (UTM_PARAMS_TO_BE_SAVED_IN_SESSION_STORAGE.includes(key)) {
          setSessionStorage(key, value);
        }
      });

      return true;
    })
  );

  return (
    <UtmContext.Provider value={{ isUtmLoaded }}>
      {children}
    </UtmContext.Provider>
  );
};

export const useUtm = () => useContext(UtmContext);

UtmProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UtmProvider;
