import BLACK_FRIDAY from 'constants/blackFriday';

export const browserOnly = (fn) => (isBrowser() ? fn() : null);

export const composeRefs = (...refs) => {
  return (node) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(node);
      }

      if (ref && 'current' in ref) {
        ref.current = node;
      }
    });
  };
};

export const copy = async (text) => {
  try {
    await navigator.clipboard.writeText(text);
  } catch {
    // error possibly caused because ins't configured WebView permission
  }
};

export const debounce = (fn, timeout = 300) => {
  let timer;

  return (...args) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      fn.apply(this, args);
    }, timeout);
  };
};

export const getFeaturedLanding = (featuredLandings) =>
  featuredLandings.length > 0 ? featuredLandings[0] : null;

export const getYTVideoId = (url) =>
  url.match(/^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\\&\\?]*).*/)[2];

export const hasUnreadMessages = (unreadMessagesCount) =>
  unreadMessagesCount > 0;

// Ignore `Viagens` landing because it are registered with wrong type
export const isBlackFridayLanding = (featuredLanding) =>
  featuredLanding?.landingType === BLACK_FRIDAY.LANDING_TYPE &&
  featuredLanding?.landingSlug !== 'viagens';

export const isBrowser = () => typeof window !== 'undefined';

export const isDenied = (permission) => permission === 'denied';

export const isDev = () => process.env.NODE_ENV === 'development';

export const isGranted = (permission) => permission === 'granted';

export const isProduction = () => process.env.NODE_ENV === 'production';

// TODO internalize rule to log only in dev mode or when an env is true
export const log = (domain, ...args) => {
  // eslint-disable-next-line no-console
  console.info(...[`[promobit-${domain}]\n`].concat(args));
};

export const markup = (html) => ({ __html: html });

export const noop = () => {};

export const pixealize = (value) => (value ? `${value}px` : '0px');

export const scrollToElement = (offsetTop) => {
  try {
    window.scroll({ top: offsetTop, left: 0, behavior: 'smooth' });
  } catch {
    // Ignore errors
  }
};

export const scrollToTop = () => {
  try {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  } catch {
    // Ignore errors
  }
};

export const runAndScrollToTop = (fn, ...args) => {
  fn(...args);
  scrollToTop();
};

export const updateFavicon = (path) => {
  const link = document.querySelector("link[rel*='icon']");
  link.href = path;
};

export const updatePageTitle = (title) => {
  document.title = title;
};

export const toMediaQueryStr = (mediaQuery) =>
  mediaQuery.substring('@media'.length + 1, mediaQuery.length);

export const waitForGlobalWindowVar = (varName, callback) => {
  let attempts = 0;

  const interval = setInterval(() => {
    if (varName in window) {
      clearInterval(interval);
      callback();
    }

    // We limit the max attempts to avoid possible performance issues
    if (attempts === 10) {
      clearInterval(interval);
    }

    attempts++;
  }, 300);
};
