import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { listen } from 'lib/eventManager';

export const OPEN_DIALOG_EVENT = 'open-dialog';

const DialogRoot = () => {
  const router = useRouter();
  const [Dialog, setDialog] = useState(null);

  const hideDialog = () => setDialog(null);

  useEffect(() => {
    router.events.on('routeChangeStart', hideDialog);

    const unlisten = listen(OPEN_DIALOG_EVENT, ({ detail }) => {
      setDialog(detail);
    });

    return () => {
      unlisten();
      router.events.off('routeChangeStart', hideDialog);
    };
  }, []);

  if (!Dialog) {
    return null;
  }

  return <Dialog.component {...Dialog.props} onClose={hideDialog} />;
};

export default DialogRoot;
