import { useState } from 'react';

import { isBrowser, toMediaQueryStr } from 'lib/utils';

import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect';

import SCREENS from 'constants/screens';

const toMediaFormat = (breakpointValue) =>
  `@media screen and (min-width: ${breakpointValue})`;

const isMatch = (mediaQuery) =>
  isBrowser() ? window.matchMedia(toMediaQueryStr(mediaQuery)).matches : null;

const useMediaQuery = (
  { shouldUpdateAtInitialLoad } = { shouldUpdateAtInitialLoad: true }
) => {
  const [
    isMediaQueryMatchersUpdatedOnClient,
    setMediaQueryMatchersUpdatedOnClient,
  ] = useState(false);
  const [mediaQueryMatchers, setMediaQueryMatchers] = useState({
    isXl: isMatch(toMediaFormat(SCREENS.xl)),
    isLg: isMatch(toMediaFormat(SCREENS.lg)),
    isMd: isMatch(toMediaFormat(SCREENS.md)),
    isSm: isMatch(toMediaFormat(SCREENS.sm)),
    isMobile:
      isMatch(toMediaFormat(SCREENS.sm)) && !isMatch(toMediaFormat(SCREENS.md)),
  });

  useIsomorphicLayoutEffect(() => {
    const handleResize = () =>
      setMediaQueryMatchers({
        isXl: isMatch(toMediaFormat(SCREENS.xl)),
        isLg: isMatch(toMediaFormat(SCREENS.lg)),
        isMd: isMatch(toMediaFormat(SCREENS.md)),
        isSm: isMatch(toMediaFormat(SCREENS.sm)),
        isMobile:
          isMatch(toMediaFormat(SCREENS.sm)) &&
          !isMatch(toMediaFormat(SCREENS.md)),
      });

    if (!isMediaQueryMatchersUpdatedOnClient && shouldUpdateAtInitialLoad) {
      handleResize();
      setMediaQueryMatchersUpdatedOnClient(true);
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [isMediaQueryMatchersUpdatedOnClient, shouldUpdateAtInitialLoad]);

  return mediaQueryMatchers;
};

export default useMediaQuery;
