import { useCallback } from 'react';

import { OPEN_DIALOG_EVENT } from 'components/Dialog/Root';

import { dispatch } from 'lib/eventManager';

const useDialog = () => {
  const showDialog = useCallback((component, props = {}) => {
    dispatch(OPEN_DIALOG_EVENT, { component, props });
  }, []);

  return { showDialog };
};

export default useDialog;
