/**
 *  TODO: polymorphism can be applied to this whole hook, way too much
 * duplicated stuff, lots of similar logic
 */
import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { sendEvent } from 'lib/gtag';
import {
  getFromLocalStorage,
  removeFromLocalStorage,
  setLocalStorage,
} from 'lib/localStorage';

import { useUser } from 'providers/UserProvider';

import { postAddCouponKeywords, postAddOfferKeywords } from 'services/wishlist';

const LocalWishlistContext = createContext({});

const LocalWishlistProvider = ({ children }) => {
  const { isLoggedIn } = useUser();
  const [localOfferKeywords, setLocalOfferKeywords] = useState(
    getFromLocalStorage('wishlist.pending_keywords')
      ? getFromLocalStorage('wishlist.pending_keywords').split(',')
      : []
  );
  const [localCouponKeywords, setLocalCouponKeywords] = useState([]);

  const { mutate: mutateAddLocalOfferKeywords } = useMutation({
    mutationFn: postAddOfferKeywords,
    onMutate: () => {
      removeFromLocalStorage('wishlist.pending_keywords');
      setLocalOfferKeywords([]);
    },
    onSuccess: () => {
      sendEvent({
        action: 'temporary_keyword_added',
        category: 'login_modal_v4',
      });
    },
  });
  const { mutate: mutateAddLocalCouponKeywords } = useMutation({
    mutationFn: postAddCouponKeywords,
    onMutate: () => {
      removeFromLocalStorage('wishlist.pending_coupons');
      setLocalCouponKeywords([]);
    },
  });

  const addLocalOfferKeyword = useCallback(
    (keyword) => {
      const isKeywordDuplicate =
        localOfferKeywords.length > 0 &&
        localOfferKeywords.some(
          (localOfferKeyword) => localOfferKeyword === keyword
        );

      if (isKeywordDuplicate) {
        throw new Error('already');
      }

      const newKeywords = localOfferKeywords
        ? [keyword, ...localOfferKeywords]
        : keyword;

      setLocalStorage('wishlist.pending_keywords', newKeywords.toString());
      setLocalOfferKeywords(newKeywords);

      return keyword;
    },
    [localOfferKeywords, setLocalStorage, setLocalOfferKeywords]
  );

  const removeLocalOfferKeyword = useCallback(
    (keyword) => {
      const newKeywords = localOfferKeywords
        ? localOfferKeywords.filter(
            (storageKeywords) => storageKeywords !== keyword
          )
        : [];

      setLocalStorage('wishlist.pending_keywords', newKeywords.toString());
      setLocalOfferKeywords(newKeywords);

      return newKeywords;
    },
    [localOfferKeywords, setLocalStorage, setLocalOfferKeywords]
  );

  const sendLocalOfferKeywordsToServer = useCallback(() => {
    if (!getFromLocalStorage('wishlist.pending_keywords')) {
      return;
    }

    mutateAddLocalOfferKeywords({
      keywords: getFromLocalStorage('wishlist.pending_keywords').split(','),
    });
  }, [getFromLocalStorage, mutateAddLocalOfferKeywords]);

  const addLocalCouponKeyword = useCallback(
    async (keyword) => {
      if (!keyword) {
        throw new Error('INVALID_KEYWORD');
      }

      const isKeywordDuplicate =
        localCouponKeywords.length > 0 &&
        localCouponKeywords.some(
          ({ keywordDisplay }) => keywordDisplay === keyword.keywordDisplay
        );

      if (isKeywordDuplicate) {
        /**
         * This is the same kind of error that the API returns, we're mimicking
         * it here so we can handle it the same way
         */
        throw new Error('already');
      }

      const newKeywords = localCouponKeywords
        ? [keyword, ...localCouponKeywords]
        : keyword;

      const { toLegacyLocalStorageCouponKeywordsObj } = await import(
        'lib/wishlist'
      );

      setLocalStorage(
        'wishlist.pending_coupons',
        JSON.stringify(toLegacyLocalStorageCouponKeywordsObj(newKeywords))
      );
      setLocalCouponKeywords(newKeywords);

      return keyword;
    },
    [localCouponKeywords]
  );

  const removeLocalCouponKeyword = useCallback(
    async (keyword) => {
      const newKeywords = localCouponKeywords
        ? localCouponKeywords.filter(
            ({ keywordDisplay }) => keywordDisplay !== keyword.keywordDisplay
          )
        : [];

      const { toLegacyLocalStorageCouponKeywordsObj } = await import(
        'lib/wishlist'
      );

      setLocalStorage(
        'wishlist.pending_coupons',
        JSON.stringify(toLegacyLocalStorageCouponKeywordsObj(newKeywords))
      );
      setLocalCouponKeywords(newKeywords);

      return newKeywords;
    },
    [localCouponKeywords]
  );

  const sendLocalCouponKeywordsToServer = useCallback(async () => {
    if (!getFromLocalStorage('wishlist.pending_coupons')) {
      return;
    }

    const [{ pick }, { parseLegacyLocalStorageCouponKeywordsObj }] =
      await Promise.all([import('lib/object'), import('lib/wishlist')]);

    mutateAddLocalCouponKeywords({
      keywords: parseLegacyLocalStorageCouponKeywordsObj(
        JSON.parse(getFromLocalStorage('wishlist.pending_coupons'))
      ).map((localCouponKeyword) =>
        pick(localCouponKeyword, ['keywordCategoryId', 'keywordStoreId'])
      ),
    });
  }, []);

  const withLocalWishlistItems = useCallback(
    (wishlist) => {
      const newWishlist = wishlist;

      if (!isLoggedIn && localOfferKeywords.length > 0) {
        // Fill items infos using offer keywords from localStorage
        newWishlist.items = localOfferKeywords.map((offerKeyword) => ({
          keywordDisplay: offerKeyword,
          keywordMax: -1,
          keywordMin: 0,
          keywordCategoryId: 0,
          keywordTop: false,
        }));
        newWishlist.suggestions = wishlist.suggestions.filter((suggestion) =>
          localOfferKeywords.some(
            (localOfferKeyword) => localOfferKeyword !== suggestion
          )
        );
      }

      return newWishlist;
    },
    [localOfferKeywords]
  );

  useEffect(() => {
    /**
     * Is preferable parse the pending coupons here instead via initializer
     * function of state because `useState` don't resolve async functions and
     * due of that, is stored the state as a Promise instead of resolved data.
     */
    const parsePendingCouponsIfExists = async () => {
      const pendingCoupons = getFromLocalStorage('wishlist.pending_coupons');

      if (pendingCoupons) {
        const { parseLegacyLocalStorageCouponKeywordsObj } = await import(
          'lib/wishlist'
        );

        setLocalCouponKeywords(
          parseLegacyLocalStorageCouponKeywordsObj(JSON.parse(pendingCoupons))
        );
      }
    };

    parsePendingCouponsIfExists();
  }, []);

  const value = useMemo(
    () => ({
      localOfferKeywords,
      addLocalOfferKeyword,
      sendLocalOfferKeywordsToServer,
      removeLocalOfferKeyword,
      localCouponKeywords,
      addLocalCouponKeyword,
      removeLocalCouponKeyword,
      sendLocalCouponKeywordsToServer,
      withLocalWishlistItems,
    }),
    [
      localOfferKeywords,
      addLocalOfferKeyword,
      removeLocalOfferKeyword,
      localCouponKeywords,
      addLocalCouponKeyword,
      removeLocalCouponKeyword,
      withLocalWishlistItems,
    ]
  );

  return (
    <LocalWishlistContext.Provider value={value}>
      {children}
    </LocalWishlistContext.Provider>
  );
};

export const useLocalWishlist = () => useContext(LocalWishlistContext);

LocalWishlistProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LocalWishlistProvider;
