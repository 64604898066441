import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import useModal from 'hooks/useModal';

import { removeHashFromUrl } from 'lib/request';

const WishlistModal = dynamic(() => import('.'), {
  ssr: false,
});

const WISHLIST_HASH = '#wishlist';

const WishlistModalOnUrlHash = () => {
  const router = useRouter();
  const { showModal } = useModal();

  useEffect(() => {
    if (window.location.hash === WISHLIST_HASH) {
      showModal(WishlistModal);
      removeHashFromUrl(router);
    }
  }, []);

  return null;
};

export default WishlistModalOnUrlHash;
