const METRIC_NOTATION_RANGES = [
  { divider: 1e18, suffix: 'E' },
  { divider: 1e15, suffix: 'P' },
  { divider: 1e12, suffix: 'T' },
  { divider: 1e9, suffix: 'G' },
  { divider: 1e6, suffix: 'M' },
  { divider: 1e3, suffix: 'k' },
];
export const formatAsBRL = (number) => {
  const numAsCurrency = formatAsCurrency({ number, style: 'currency' });

  return numAsCurrency.substring(3, numAsCurrency.length);
};

export const formatAsBRLWithSignal = (number) => formatAsCurrency({ number });

export const formatBRLCurrencyAsNumber = (string) => {
  if (!string || typeof string === 'number') {
    return string;
  }

  return Number(string.replace('.', '').replace(',', '.'));
};

export const formatAsCurrency = ({
  currency = 'BRL',
  currencyDisplay,
  number,
  locale = 'pt-BR',
  style = 'currency',
}) =>
  new Intl.NumberFormat(locale, {
    currency,
    currencyDisplay,
    style,
  }).format(number);

export const getCurrentXpValue = (xp, xpNextLevel) =>
  ((xp * 100) / xpNextLevel).toFixed(2);

export const isFloat = (number) => !!(number % 1);

export const isInteger = (number) => Number.isInteger(number);

export const isNumber = (number) => isFloat(number) || isInteger(number);

export const toMetricNotation = (n) => {
  const range = METRIC_NOTATION_RANGES.find(({ divider }) => n > divider);

  return range ? (n / range.divider).toString() + range.suffix : n.toString();
};

export const toPtBrDecimalNumber = (number) =>
  new Intl.NumberFormat('pt-BR', { style: 'decimal' }).format(number);
