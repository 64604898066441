const IMAGE = {
  MAX_IMAGE_SIZE_KB: 10_000_000, // 10MB
  VALID_IMAGE_MIME_TYPES: [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/webp',
  ],
};

export default IMAGE;
