import PropTypes from 'prop-types';

import ExperimentProvider from './ExperimentProvider';
import GlobalQueriesProvider from './GlobalQueriesProvider';
import LocalWishlistProvider from './LocalWishlistProvider';
import QueryClientProvider from './QueryClientProvider';
import TopbarProvider from './TopbarProvider';
import UserProvider from './UserProvider';
import UtmProvider from './UtmProvider';

const GlobalProvider = ({
  activeOffersListTab,
  categories = null,
  children,
  featuredLanding = null,
  experiments = null,
  highlightedLandings = null,
  stores = null,
  topbarAd = null,
}) => (
  <QueryClientProvider>
    <GlobalQueriesProvider
      activeOffersListTabName={activeOffersListTab}
      categories={categories}
      featuredLanding={featuredLanding}
      highlightedLandings={highlightedLandings}
      stores={stores}
      topbarAd={topbarAd}
    >
      <LocalWishlistProvider>
        <ExperimentProvider ssrActiveExperiments={experiments}>
          <TopbarProvider isTopbarAdActive={!!topbarAd}>
            <UserProvider>
              <UtmProvider>{children}</UtmProvider>
            </UserProvider>
          </TopbarProvider>
        </ExperimentProvider>
      </LocalWishlistProvider>
    </GlobalQueriesProvider>
  </QueryClientProvider>
);

GlobalProvider.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  categories: PropTypes.array,
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  stores: PropTypes.array,
  topbarAd: PropTypes.shape({
    imageSrc: PropTypes.string,
    description: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    isClosable: PropTypes.bool.isRequired,
    bgcolor: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
};

export default GlobalProvider;
