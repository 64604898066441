import APP from './app';

const BLACK_FRIDAY = {
  CATEGORY_NAME: 'Black Friday',
  LANDING_TYPE: 'BLACK_FRIDAY',
  /**
   * Even that majority of props aren't utilized, we reproduces the API
   * data-structure for padronization and avoid unexpected behaviors.
   */
  CARD_ADS: [
    {
      id: 11292024, // Date of BF 2024
      position: 1,
      description:
        'Os produtos que você ama pelo preço que você sempre quis! Somente para Promolovers!',
      url: null,
      image: `${APP.LOCAL_IMAGE_PATH}/features/black-friday/card-banner-v2@desktop.png`,
      mobileImage: `${APP.LOCAL_IMAGE_PATH}/features/black-friday/card-banner-v2@mobile.png`,
      tabletImage: null,
      bgcolor: [],
      isClosable: false,
      type: 'CARD_AD',
      storeSlug: null,
      event: null,
      height: null,
      onClick: () => {},
    },
  ],
};

export default BLACK_FRIDAY;
