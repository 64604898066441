/**
 * TODO: send currently active experiments and user variant
 * as a custom dimension
 */
import Script from 'next/script';

import { markup } from 'lib/utils';

const GoogleTagManager = () => {
  const gtmId = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;

  if (!gtmId) {
    return null;
  }

  return (
    <>
      <Script
        id="gtm-loader"
        src={`https://www.googletagmanager.com/gtag/js?id=${gtmId}`}
        strategy="afterInteractive"
      />
      <Script
        dangerouslySetInnerHTML={markup(`
          window.dataLayer = window.dataLayer || [];
          window.gtag = function gtag() { window.dataLayer.push(arguments); };
          window.gtag('js', new Date());
          window.gtag('config', '${gtmId}',{custom_map:{dimension2:'version'}});
        `)}
        id="gtm-config"
        strategy="afterInteractive"
      />
    </>
  );
};

export default GoogleTagManager;
