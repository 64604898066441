/**
 * Sizes must be used as the following: /p/${size}/image_file_name.jpg
 * Current available sizes:
 * 25,26,28,30,32,35,36,40,45,50,54,55,56,60,62,64,65,70,72,74,76,78,80,85,90,
 * 96,100,108,110,120,124,130,140,144,160,180,168,180,192,200,220,224,230,231,
 * 232,233,234,235,240,268,300,328,336,400,448,492,500,600,656,700,720,750,800,
 * 900,1200
 */
import { insertInArray } from './array';
import { getLastPathPosition } from './request';

import APP from 'constants/app';
import IMAGE from 'constants/image';

const IMAGE_SOURCE_PATHS_TO_SKIP = ['https://', 'http://', '//p'];

export const getArticleImageLink = (image, size) => {
  if (!image || !size) {
    return null;
  }

  const imagePathWithoutFile = image.substring(0, image.lastIndexOf('/'));
  const imageFile = image.substring(image.lastIndexOf('/'));

  return `${imagePathWithoutFile}/ar/${size}${imageFile}`;
};

export const getArticleReviewImageLink = (image, size) => {
  if (!image || !size) {
    return null;
  }

  const imagePathWithoutFile = image.substring(0, image.lastIndexOf('/'));
  const imageFile = image.substring(image.lastIndexOf('/'));

  return `${imagePathWithoutFile}/ar/${size}${imageFile}`;
};

const getFileNameFromUrl = (url) => {
  const regexMatch =
    url.trim().match(/[^/\\&?]+\.\w{3,4}(?=([?&].*$|$))/) || [];
  const [fileName] = regexMatch;

  return fileName || null;
};

export const getImageSourcePath = (image = '') =>
  IMAGE_SOURCE_PATHS_TO_SKIP.some((sourcePath) => image.includes(sourcePath))
    ? ''
    : APP.REMOTE_IMAGE_PATH;

/**
 * API is inconsistent with image paths, these are all
 * currently valid cases:
 * - https://www.promobit.com.br/static/p/120902604915018501849673458767.png,
 * - /static/p/120902604915018501849673458767.png,
 * - 120902604915018501849673458767.png,
 *
 * We have to resolve them all for now. File extensions are also not normalized
 * so they always comes with the path and file name. They could be either
 * .jpg or .png.
 */
export const getOfferImageLink = (image, size) => {
  if (!image) {
    return '';
  }

  const imageSourcePath = getImageSourcePath(image);
  const imageFileName = image.substring(getLastPathPosition(image));
  // There is also cases where images got spaces
  const trimmedImage = image.trim();

  return size
    ? `${imageSourcePath}${trimmedImage.substring(
        0,
        getLastPathPosition(trimmedImage)
      )}/${size}${imageFileName}`
    : `${imageSourcePath}${trimmedImage.trim()}`;
};

// API does not normalize its source of truth for user images
// paths so we have to do it here and resize them according to each
// service too
export const getUserAvatarUrl = (userPhoto, size = 60) => {
  if (!userPhoto || userPhoto.includes('avatar_default.png')) {
    return `${APP.REMOTE_IMAGE_PATH}/profile/${size}/avatar_default.png?=v2`;
  }

  if (userPhoto.includes('gravatar')) {
    return userPhoto.replace(/s=([0-9]+)/, `s=${size}`);
  }

  if (userPhoto.includes('googleusercontent.com')) {
    return userPhoto.replace(/s([0-9]+)-c/, `s${size}-c`);
  }

  if (userPhoto.includes('https://graph.facebook.com')) {
    return userPhoto;
  }

  const userPhotoUrlArray = userPhoto.split('/');
  const userPhotoWithSize = insertInArray({
    array: userPhotoUrlArray,
    item: size.toString(),
    position: userPhotoUrlArray.length - 1,
  }).join('/');

  if (userPhoto.includes('//p.promobit.com.br')) {
    // There are cases with and without https
    return `https:${userPhotoWithSize.replace(/^(?:https?:)?/i, '')}`;
  }

  const userPhotoWithoutRemotePath = userPhotoWithSize.replace(
    /https:\/\/www.promobit.com.br|\/\/www.promobit.com.br|https:\/\/i.promobit.com.br/g,
    ''
  );

  return `${APP.REMOTE_IMAGE_PATH}${userPhotoWithoutRemotePath}`;
};

/**
 * This is a workaround for fixing the absolute path that comes from the API
 * for store images. This fixes the path and also resizes it.
 */
export const getStoreImageUrl = (url, size = 60) =>
  `${APP.REMOTE_IMAGE_PATH}/static/p/ar/${size}/${getFileNameFromUrl(url)}`;

export const isImageMimeTypeValid = (image) =>
  IMAGE.VALID_IMAGE_MIME_TYPES.some((type) => type === image.type);

export const isImageSizeValid = (image) =>
  image.size <= IMAGE.MAX_IMAGE_SIZE_KB;

/**
 * Removes query strings from image url
 */
export const sanitizeExternalImageUrl = (imageUrl) =>
  imageUrl.includes('?') ? imageUrl.split('?')[0] : imageUrl;

export const toArticleImageAltText = (articleTitle) =>
  `Capa do artigo ${articleTitle}`;

export const toOfferImageAltText = (offerTitle) =>
  `Imagem da oferta ${offerTitle}`;

export const toUserAvatarAltText = (userName) => `Avatar do membro ${userName}`;

export const toStoreImageAltText = (storeName) => `Logo da loja ${storeName}`;

export const toFallbackImageAltText = (alt) =>
  alt || 'Imagem exemplificando o trecho de texto anterior';

export const toImageWithPreservedAspectRatioSize = (image, size) => {
  const imagePathArr = image.split('/');
  const imageWithSize = insertInArray({
    array: imagePathArr,
    /**
     * `ar/` is a path that, when added to our image service,
     * keeps the aspect ratio based on the image's width.
     */
    item: `ar/${size.toString()}`,
    position: imagePathArr.length - 1,
  }).join('/');

  return imageWithSize;
};
