import { DefaultSeo, LogoJsonLd } from 'next-seo';
import { useRouter } from 'next/router';

import { canonize } from 'lib/links';

import SEO from '../../../next-seo.config';

import APP from 'constants/app';

const UniversalSchemas = () => {
  const router = useRouter();

  const url = canonize(router.asPath);

  return (
    <>
      <DefaultSeo
        {...SEO}
        canonical={url}
        openGraph={{ ...SEO.openGraph, url }}
      />
      <LogoJsonLd
        logo={`${APP.URL}${APP.LOCAL_IMAGE_PATH}/features/topbar/promobit-logo.svg`}
        url={APP.URL}
      />
    </>
  );
};

export default UniversalSchemas;
