class RequestError extends Error {
  constructor({ status, code }) {
    super(status);

    this.name = 'RequestError';
    this.status = String(status);
    this.code = code;
  }
}

export default RequestError;
