import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

import useModal from 'hooks/useModal';

import { isEmpty } from 'lib/object';
import { getFromSessionStorage, getUtmsFromSession } from 'lib/sessionStorage';

import { useUser } from 'providers/UserProvider';
import { useUtm } from 'providers/UtmProvider';

import LINKS from 'constants/links';
import SESSION_STORAGE from 'constants/sessionStorage';

const ShareCampaignLoginModal = dynamic(() => import('.'), { ssr: false });

const PATHNAMES_THAT_SHARE_CAMPAIGN_LOGIN_MODAL_CAN_BE_OPENED = [
  LINKS.HOME,
  LINKS.BLOG,
];

const isPathnameToOpenShareCampaignLoginModal = (pathname) =>
  PATHNAMES_THAT_SHARE_CAMPAIGN_LOGIN_MODAL_CAN_BE_OPENED.some((link) =>
    link.length === 1 ? link === pathname : pathname.includes(link)
  );

const DynamicallyLoadedShareCampaignLoginModal = ({
  hasOtherModalWithHigherPriorityOpen = false,
}) => {
  const { pathname } = useRouter();
  const { isUtmLoaded } = useUtm();
  const { loadingLoggedInUser, isLoggedIn } = useUser();
  const { showModal } = useModal();

  useEffect(() => {
    const verifyAndOpenModal = async () => {
      if (
        !isPathnameToOpenShareCampaignLoginModal(pathname) ||
        !isUtmLoaded ||
        hasOtherModalWithHigherPriorityOpen ||
        loadingLoggedInUser
      ) {
        return;
      }

      const shouldOpenShareCampaignLoginModal =
        !isLoggedIn &&
        !isEmpty(getUtmsFromSession()) &&
        !getFromSessionStorage(
          SESSION_STORAGE.SHOWED_SHARE_CAMPAIGN_LOGIN_MODAL
        );

      if (shouldOpenShareCampaignLoginModal) {
        showModal(ShareCampaignLoginModal);
      }
    };

    verifyAndOpenModal();
  }, [isUtmLoaded, loadingLoggedInUser]);

  return <></>;
};

DynamicallyLoadedShareCampaignLoginModal.propTypes = {
  /**
   * this prop is a palliative way to prevent conflicts between modals that open
   * by query params
   */
  hasOtherModalWithHigherPriorityOpen: PropTypes.bool,
};

export default DynamicallyLoadedShareCampaignLoginModal;
