/**
 * TODO:
 * When is necessary open various components in the same time, we can add a
 * timer between the close of first component to open the second one, improving
 * more the user-experience.
 */
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import useMediaQuery from 'hooks/useMediaQuery';
import useQueue from 'hooks/useQueue';
import useScrollPosition from 'hooks/useScrollPosition';

import { listen, mergeListeners } from 'lib/eventManager';

import LINKS from 'constants/links';

const FOOTER_HEIGHT_WITH_OFFSET = 2600;
const MIN_SCROLL_POSITION_TO_OPEN = 400;
const MIN_SCROLL_POSITION_TO_OPEN_HIGHER_RES = 150;
export const CLOSE_SCROLLABLE_EVENT = 'close-scroll';
export const OPEN_SCROLLABLE_EVENT = 'open-scroll';

const getMaxAllowedPosWithoutFooter = () =>
  document.body.offsetHeight - FOOTER_HEIGHT_WITH_OFFSET;

const Root = () => {
  const router = useRouter();
  const { isLg } = useMediaQuery();
  const { active: ActiveComponent, queuer } = useQueue();
  const [isActive, setActive] = useState(false);

  useEffect(() => {
    const openScrollable = ({ detail }) => {
      const { Component, key, props, config } = detail;

      const onCloseHandler = () => {
        if (props.onClose) {
          props.onClose();
        }

        queuer({
          action: 'dequeue',
          key,
        });
      };

      queuer({
        action: 'enqueue',
        key,
        priority: config.priority,
        data: {
          Component,
          persist: config.persist,
          props: { ...props, onClose: onCloseHandler },
        },
      });
    };

    const closeScrollable = ({ detail }) => {
      const { key } = detail;

      queuer({
        action: 'dequeue',
        key,
      });
    };

    return mergeListeners(
      listen(OPEN_SCROLLABLE_EVENT, openScrollable),
      listen(CLOSE_SCROLLABLE_EVENT, closeScrollable)
    );
  }, []);

  useEffect(() => {
    const onClose = () => {
      if (
        ActiveComponent &&
        !ActiveComponent.persist &&
        ActiveComponent.props &&
        'onClose' in ActiveComponent.props
      ) {
        ActiveComponent.props.onClose();
      }
    };

    router.events.on('routeChangeStart', onClose);

    return () => {
      router.events.off('routeChangeStart', onClose);
    };
  }, [ActiveComponent]);

  useScrollPosition(
    ({ currPos: { y } }) => {
      setActive((prevState) => {
        const isHomePage = router.asPath === LINKS.HOME;
        const isActiveOnLowerRes =
          (prevState || y >= MIN_SCROLL_POSITION_TO_OPEN) &&
          (isHomePage || y < getMaxAllowedPosWithoutFooter());
        const isActiveOnHigherRes =
          prevState || y > MIN_SCROLL_POSITION_TO_OPEN_HIGHER_RES;

        return !isLg ? isActiveOnLowerRes : isActiveOnHigherRes;
      });
    },
    { deps: [ActiveComponent, isLg, router.asPath], enabled: !!ActiveComponent }
  );

  return ActiveComponent && isActive ? (
    <ActiveComponent.Component {...ActiveComponent.props} />
  ) : null;
};

export default Root;
