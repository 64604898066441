const LANDINGS = {
  TYPES: {
    BLACK_FRIDAY: 'BLACK_FRIDAY',
    INSTITUTIONAL: 'INSTITUTIONAL',
    SEASONAL: 'SEASONAL',
  },
  OFFERS_SERVER_LIMIT: 24,
};

export default LANDINGS;
