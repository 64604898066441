import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import useDialog from 'hooks/useDialog';
import useLGPDConsents from 'hooks/useLGPDConsents';
import usePendingComments from 'hooks/usePendingComments';
import useSingleton from 'hooks/useSingleton';
import useTheme from 'hooks/useTheme';

import {
  getFromLocalStorage,
  removeFromLocalStorage,
  setLocalStorage,
} from 'lib/localStorage';
import { sendSupixEvent } from 'lib/supix';
import { getLoggedInUser, setLoggedInUserToLocalStorage } from 'lib/user';
import { browserOnly, isDenied, isDev, noop } from 'lib/utils';

import { useLocalWishlist } from './LocalWishlistProvider';

import COOKIES from 'constants/cookies';
import LOCAL_STORAGE from 'constants/localStorage';
import LOGIN from 'constants/login';

const LoginDialog = dynamic(() => import('components/LoginDialog'), {
  ssr: false,
});

const BROADCAST_CHANNEL_FALLBACK = {
  addEventListener: noop,
  removeEventListener: noop,
  postMessage: noop,
};
const LOGIN_REALIZED_EVENT = 'login';
const LOGOUT_REALIZED_EVENT = 'logout';
const TIMEOUT_TO_CREATE_RECOVERED_COMMENTS = 3000; // 3 seconds
const TOKEN_EXPIRATION_TIME = 60 * 60 * 24 * 365; // 1 year

const UserContext = createContext({
  loadingLoggedInUser: true,
  LoggedInUser: null,
  isLoggedIn: null,
  login: noop,
  logout: noop,
  runCallbackIfLoggedIn: noop,
  setLoggedInUser: noop,
});

const UserProvider = ({ children }) => {
  const { showDialog } = useDialog();
  const router = useRouter();
  const {
    localOfferKeywords,
    localCouponKeywords,
    sendLocalCouponKeywordsToServer,
    sendLocalOfferKeywordsToServer,
  } = useLocalWishlist();
  const { sendPendingComments } = usePendingComments();
  const { changeThemeToStoredUserPreference, resetUserThemePreference } =
    useTheme();
  const {
    postGiveLGPDConsents,
    resetLGPDConsentsStorage,
    sendUserToAnalytics,
  } = useLGPDConsents();
  /**
   * Despite the majority of browsers already support BroadcastChannel API, we
   * have a lot of users that uses mobile safari <15.4 and because of that, to
   * avoid errors caused by a secondary feature, in these edge-cases we store
   * in a dummy object.
   */
  const channel = useSingleton(
    browserOnly(() =>
      'BroadcastChannel' in window
        ? new BroadcastChannel('login_cannel')
        : BROADCAST_CHANNEL_FALLBACK
    )
  );
  const [LoggedInUser, setLoggedInUser] = useState(null);
  const [user, setUser] = useState({
    loadingLoggedInUser: true,
    isLoggedIn: false,
  });

  useEffect(() => {
    if (user.loadingLoggedInUser || !user.isLoggedIn) {
      return;
    }

    const redirectPathname = router.query[LOGIN.AFTER_LOGIN_REDIRECT_QS];

    if (!redirectPathname) {
      return;
    }

    router.replace(redirectPathname);
  }, [router, user.loadingLoggedInUser, user.isLoggedIn]);

  const resetUserPreferences = useCallback(async () => {
    const { clearLoginStorage } = await import('lib/user');

    resetUserThemePreference();
    clearLoginStorage();
    resetLGPDConsentsStorage();
  }, []);

  const saveLoginIfExists = useCallback(async ({ loggedInUser, token }) => {
    if (!loggedInUser && token) {
      await resetUserPreferences();
    }

    if (!loggedInUser) {
      return;
    }

    const { saveUserAsLegacyModeToLocalStorage } = await import('lib/wishlist');

    // Workaround for legacy compat mode
    saveUserAsLegacyModeToLocalStorage(loggedInUser);
    sendUserToAnalytics(loggedInUser);

    if (!getFromLocalStorage(LOCAL_STORAGE.IS_SUPIX_COLLECTED)) {
      await sendSupixEvent('/usa', { id: loggedInUser.userId });

      setLocalStorage(LOCAL_STORAGE.IS_SUPIX_COLLECTED, 'true');
    }
  }, []);

  const checkLogin = useCallback(
    async ({ token }) => {
      const loggedInUser = await getLoggedInUser({
        skipOldApiWorkaround: process.env.NODE_ENV === 'development',
        token,
      });

      await saveLoginIfExists({
        loggedInUser,
        token,
      });
      changeThemeToStoredUserPreference();
      setLoggedInUser(loggedInUser);
      setUser({
        loadingLoggedInUser: false,
        isLoggedIn: loggedInUser !== null,
      });

      if (loggedInUser) {
        const { setUser: setSentryUser } = await import('@sentry/nextjs');

        setSentryUser({
          id: loggedInUser.userId,
          username: loggedInUser.userUsername,
        });
      }
    },
    [changeThemeToStoredUserPreference]
  );

  const login = useCallback(
    async ({ token }) => {
      try {
        const [
          { setCookie },
          { postCreateWebpush },
          { removeFromSessionStorage },
        ] = await Promise.all([
          import('cookies-next'),
          import('services/webpush'),
          import('lib/sessionStorage'),
        ]);

        setLocalStorage(LOCAL_STORAGE.ACCESS_TOKEN, token);
        removeFromLocalStorage(LOCAL_STORAGE.SHOWED_WELCOME_NOTIFICATION);
        setCookie(COOKIES.IS_USER_LOGGED, true, {
          maxAge: TOKEN_EXPIRATION_TIME,
          sameSite: true,
        });
        removeFromSessionStorage('utm_campaign');

        setUser((prevState) => ({
          ...prevState,
          loadingLoggedInUser: false,
        }));

        if (localOfferKeywords.length > 0) {
          await sendLocalOfferKeywordsToServer();
        }

        if (localCouponKeywords.length > 0) {
          await sendLocalCouponKeywordsToServer();
        }

        const webpushHash = getFromLocalStorage(LOCAL_STORAGE.WEBPUSH_HASH);

        if (webpushHash) {
          await postCreateWebpush({ userAuthToken: token, webpushHash });
        }

        await postGiveLGPDConsents();
        channel.postMessage(LOGIN_REALIZED_EVENT);

        window.location.reload();
      } catch (e) {
        const { captureException } = await import('@sentry/nextjs');

        captureException(e);

        await resetUserPreferences();
        setUser((lastUserState) => ({
          ...lastUserState,
          loadingLoggedInUser: false,
        }));
      }
    },
    [channel]
  );

  const logout = useCallback(async () => {
    await sendSupixEvent('/clr');
    await resetUserPreferences();

    setUser((lastUserState) => ({
      ...lastUserState,
      isLoggedIn: false,
    }));
    setLoggedInUser(null);

    channel.postMessage(LOGOUT_REALIZED_EVENT);

    // Workaround for legacy compat logout
    if (isDev()) {
      window.location.reload();
      return;
    }

    window.location.assign(
      `https://${process.env.NEXT_PUBLIC_SUBDOMAIN}.promobit.com.br/User/logout`
    );
  }, [channel]);

  const runCallbackIfLoggedIn = useCallback(
    (callback, ...args) => {
      if (user.loadingLoggedInUser) {
        return;
      }

      if (!user.isLoggedIn) {
        showDialog(LoginDialog);
        return;
      }

      callback(...args);
    },
    [user]
  );

  useEffect(() => {
    checkLogin({ token: getFromLocalStorage(LOCAL_STORAGE.ACCESS_TOKEN) });
  }, []);

  useEffect(() => {
    if (user.loadingLoggedInUser || !user.isLoggedIn) {
      return;
    }

    /**
     * In some unknown case where the user got logged in and we try to send
     * the comments too fast right after that the server returns a 500 error.
     * To mitigate this on our side, we wait a generous
     * `TIMEOUT_TO_CREATE_RECOVERED_COMMENTS` timeout to happen before sending
     * them.
     */
    setTimeout(sendPendingComments, TIMEOUT_TO_CREATE_RECOVERED_COMMENTS);
  }, [user.isLoggedIn, user.loadingLoggedInUser]);

  useEffect(() => {
    const onMessage = async (event) => {
      if (
        event.data === LOGIN_REALIZED_EVENT ||
        event.data === LOGOUT_REALIZED_EVENT
      ) {
        window.location.reload();
      }
    };

    channel.addEventListener('message', onMessage);

    return () => {
      channel.removeEventListener('message', onMessage);
    };
  }, [channel]);

  useEffect(() => {
    if (user.loadingLoggedInUser) {
      return;
    }

    const requestSubscription = async () => {
      const isNotificationsSubscriptionOnboardingHappened =
        !!getFromLocalStorage(LOCAL_STORAGE.SHOWED_WELCOME_NOTIFICATION);

      if (
        isNotificationsSubscriptionOnboardingHappened ||
        !('Notification' in window) ||
        isDenied(Notification.permission)
      ) {
        return;
      }

      const { requestNotificationSubscription, isNotificationsSupported } =
        await import('lib/notificationsSubscription');

      const isSupported = await isNotificationsSupported();

      if (!isSupported) {
        return;
      }

      return requestNotificationSubscription();
    };

    return requestSubscription();
  }, [user]);

  const updateLoggedInUser = (updatedUser) => {
    setLoggedInUserToLocalStorage(updatedUser);
    setLoggedInUser(updatedUser);
  };

  const value = useMemo(
    () => ({
      ...user,
      LoggedInUser,
      login,
      logout,
      runCallbackIfLoggedIn,
      setLoggedInUser: updateLoggedInUser,
    }),
    [user, LoggedInUser, login, logout, runCallbackIfLoggedIn]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = () => useContext(UserContext);

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserProvider;
