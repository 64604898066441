const listenersStore = new Map();
const payloadsStore = new Map();

export const listen = (event, listenerFn, options) => {
  if (options?.runLostEmits) {
    const dispatchedPayloads = payloadsStore.get(event);

    if (dispatchedPayloads) {
      dispatchedPayloads.forEach((payload) => listenerFn({ detail: payload }));
    }
  }

  if (!listenersStore.has(event)) {
    listenersStore.set(event, []);
  }

  const storedListeners = listenersStore.get(event);

  storedListeners.push(listenerFn);
  listenersStore.set(event, storedListeners);

  document.addEventListener(event, listenerFn);

  return () => {
    unlisten(event, listenerFn);
  };
};

const unlisten = (event, listenerFn) => {
  const storedListeners = listenersStore.get(event);

  if (!storedListeners) {
    return;
  }

  listenersStore.set(
    event,
    storedListeners.filter((fn) => fn !== listenerFn)
  );
  document.removeEventListener(event, listenerFn);
};

export const unlistenAll = (event) => {
  const storedListeners = listenersStore.get(event);

  if (!storedListeners) {
    return;
  }

  storedListeners.forEach((listenerFn) => {
    document.removeEventListener(event, listenerFn);
  });
};

export const dispatch = (event, payload = null, options) => {
  document.dispatchEvent(
    new CustomEvent(event, {
      detail: payload,
    })
  );

  if (options?.saveEventEmit) {
    if (!payloadsStore.has(event)) {
      payloadsStore.set(event, [payload]);
      return;
    }

    const storedEmits = payloadsStore.get(event);

    storedEmits.push(payload);
    payloadsStore.set(event, storedEmits);
  }
};

export const mergeListeners =
  (...listeners) =>
  () => {
    listeners.forEach((cleanup) => cleanup());
  };
