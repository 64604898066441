import { appendQsParams, del, get, post } from 'lib/request';

const PRIME_DAY_OFFER_SLUG =
  'assine-gratis-o-amazon-prime-e-aproveite-os-beneficios-466706';

export const delOfferCategoryFilter = async ({ categoryId }) =>
  del(`/offer/filters/category/${categoryId}`);

export const delOfferReaction = ({ offerId }) =>
  del(`/offers/${offerId}/ratings`);

export const delOfferSubcategoryFilter = async ({ subcategoryId }) =>
  del(`/offer/filters/subcategory/${subcategoryId}`);

/**
 * Despite the default sort of this endpoint to be `relevance`, sending this
 * same sort in the request makes a different behavior in the API, allowing make
 * special filters that actually we don't make in this project. But, to avoid
 * possible future problems due this special behavior, like wrong pointer in
 * the API query, is preferable pin this sort.
 */
export const getBestOffersList = async (params, opts) =>
  getGenericOffersList('/offers/best', { sort: 'relevance', ...params }, opts);

export const getCashbackAmeOffersList = async ({ after, limit, sort }, opts) =>
  get(appendQsParams('/offers/cashback-ame', { after, limit, sort }), opts);

export const getFeaturedOffersList = (opts) => get('/offers/featured', opts);

getFeaturedOffersList.fallback = [];

export const getFreeOffersList = async ({ after, limit, sort } = {}, opts) =>
  get(appendQsParams('/offers/free', { after, limit, sort }), opts);

export const getInternationalOffersList = async (
  { after, limit, sort },
  opts
) => get(appendQsParams('/offers/international', { after, limit, sort }), opts);

export const getOfferAuthors = ({ offerId }, opts) =>
  get(`/v4/offers/${offerId}/authors`, opts);

export const getOfferInfo = async ({ offerId, meliuzbit }, opts) => {
  const meliuzBitNumber = Number(meliuzbit);
  let apiPath = `/offer/${offerId}`;

  if (meliuzBitNumber === 1) {
    apiPath = `${apiPath}?meliuzbit=${meliuzBitNumber}`;
  }

  return get(apiPath, opts);
};

export const getOfferReviews = async ({ offerSlug, sort, limit }) =>
  get(appendQsParams(`/v4/offers/${offerSlug}/reviews`, { sort, limit }));

getOfferReviews.fallback = { meta: { items: 0 }, data: [] };

export const getRelatedOffers = async ({ offerId, limit }) =>
  get(appendQsParams(`/v4/offers/${offerId}/related-offers`, { limit }));

const getGenericOffersList = async (
  endpoint,
  { after, before, filters, limit, sort } = {},
  opts
) => {
  const qs = { after, before, sort, limit };

  if (filters?.categories.length > 0) {
    qs.categories_excluded = filters.categories.map(
      ({ categoryId }) => categoryId
    );
  }

  if (filters?.subcategories.length > 0) {
    qs.subcategories_excluded = filters.subcategories.map(
      ({ subcategoryId }) => subcategoryId
    );
  }

  if (filters?.isInternationalOffersActive === false) {
    qs.only_national = '1';
  }

  return get(appendQsParams(endpoint, qs), opts);
};

export const getOffersList = (params, opts) =>
  getGenericOffersList('/offers', { sort: 'latest', ...params }, opts);

export const getOfferTopContributorsList = async ({ days, limit }, opts) =>
  get(appendQsParams(`/offer/top-contributors/days/${days}`, { limit }), opts);

export const getOfferValidationInfo = async ({ offerUrl }, opts) =>
  get(appendQsParams('/offer/validation', { url: offerUrl }), opts);

export const getPrimeDayOfferInfo = async () => {
  const offer = await getOfferInfo({ offerId: PRIME_DAY_OFFER_SLUG });

  if (!offer) {
    return null;
  }

  return {
    authorCount: offer.authorCount,
    authors: offer.authors,
    categoryId: offer.categoryId,
    categorySlug: offer.categorySlug,
    key: `${offer.offerId}-${offer.offerId}`,
    offerClicks: offer.offerClicks,
    offerComments: offer.offerCommentsCount,
    offerCoupon: offer.offerCoupon,
    offerId: offer.offerId,
    offerIsHighlight: offer.offerIsHighlight,
    offerLikes: offer.offerLikes,
    offerOldPrice: offer.offerOldPrice,
    offerPhoto: offer.offerPhoto,
    offerPrice: offer.offerPrice,
    offerPriceType: offer.offerPriceType,
    offerPublished: offer.offerPublished,
    offerSlug: offer.offerSlug,
    offerStatusName: offer.offerStatusName,
    offerTags: offer.offerTags,
    offerTitle: offer.offerTitle,
    ratings: offer.ratings,
    storeDomain: offer.storeDomain,
    storeId: offer.storeId,
    storeImage: offer.storeImage,
    storeName: offer.storeName,
    subcategoryId: offer.subcategoryId,
    subcategoryName: offer.subcategoryName,
    subcategorySlug: offer.subcategorySlug,
    suggestionKeywords: offer.offerKeywords.join(','),
    userId: offer.userId,
    userName: offer.userName,
    userPhoto: offer.userPhoto,
    userTypeName: 'USER',
    userUsername: offer.userUsername,
  };
};

export const getTopOffersList = async (params, opts) =>
  getGenericOffersList('/offers/top', { sort: 'latest', ...params }, opts);

getTopOffersList.fallback = { offers: [] };

export const getRecentOffersList = async (params, opts) =>
  getGenericOffersList('/offers/recents', { sort: 'latest', ...params }, opts);

export const getUserOfferFilters = (opts) => get('/offer/filters', opts);

export const getUserOfferLikes = (opts) => get(`/offer/likes`, opts);

export const getUserSubscription = async ({ offerId }, opts) =>
  get(`/offer/${offerId}/subscription`, opts);

export const postEditOfferInfo = ({
  offerCoupon,
  offerId,
  offerInstructions,
  offerPrice,
  offerPriceType,
}) =>
  post(`/offer/${offerId}/revision`, {
    body: {
      offerCoupon,
      offerInstructions,
      offerPrice,
      offerPriceType,
    },
  });

export const postNewOffer = async ({
  modified,
  offerCoupon,
  offerInstructions,
  offerPhoto,
  offerPrice,
  offerPriceType,
  offerTitle,
  offerUrl,
}) => {
  const data = {
    modified,
    offerCoupon,
    offerInstructions,
    offerPrice,
    offerPriceType,
    offerTitle,
    offerUrl,
  };

  if (offerPhoto) {
    data.offerPhoto = offerPhoto;
  }

  return post('/offer', { body: data });
};

export const postOfferCategoryFilter = async ({ categoryId }) =>
  post('/offer/filters/category', { body: { categoryId } });

export const postOfferReaction = ({ offerId, reaction }) =>
  post(`/offers/${offerId}/ratings`, { body: { type: reaction } });

export const postOfferReport = async ({ offerId, reportType, reportText }) => {
  const body = { reportType };

  if (reportText) {
    body.reportText = reportText;
  }

  return post(`/offer/${offerId}/report`, { body });
};

export const postOfferSubcategoryFilter = async ({ subcategoryId }) =>
  post('/offer/filters/subcategory', { body: { subcategoryId } });

export const postUserOfferLike = ({ offerId }) =>
  post(`/offer/${offerId}/like`);

export const postUserOfferSubscription = async ({ offerId }) =>
  post(`/offer/${offerId}/subscription`);
