import { useCallback } from 'react';

import {
  CLOSE_SCROLLABLE_EVENT,
  OPEN_SCROLLABLE_EVENT,
} from 'components/ScrollControlRoot';

import { dispatch } from 'lib/eventManager';

const useScrollControl = () => {
  const showScrollable = useCallback(
    (Component, props = {}, { key, priority = 1, persist = false } = {}) => {
      dispatch(OPEN_SCROLLABLE_EVENT, {
        Component,
        props,
        key: key || Math.random(),
        config: { priority, persist },
      });
    },
    []
  );

  const closeScrollable = useCallback((key) => {
    dispatch(CLOSE_SCROLLABLE_EVENT, { key });
  }, []);

  return { closeScrollable, showScrollable };
};

export default useScrollControl;
