import PropTypes from 'prop-types';
import { cloneElement, createElement, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

import Text from '../Text';

const SIZES = ['size1', 'size2', 'size3', 'size4'];
const TYPES = [
  'primary',
  'primary-stroke',
  'primary-ghost',
  'secondary',
  'secondary-stroke',
  'secondary-ghost',
  'success',
  'success-stroke',
  'success-ghost',
  'error',
  'error-stroke',
  'error-ghost',
  'neutral',
  'neutral-stroke',
  'neutral-ghost',
  'like',
  'liked',
  'dislike',
  'disliked',
];

const HTML_MARKUP_OPTIONS = { button: 'button', a: 'a' };

const iconsSizeMap = {
  size1: 'size1',
  size2: 'size1',
  size3: 'size2',
  size4: 'size3',
};

const iconOnlySizes = {
  size1: 'size-10',
  size2: 'size-9',
  size3: 'size-8',
  size4: 'size-7',
};

const typeStyles = {
  primary:
    'border-primary-300 bg-primary-300 focus:ring-primary-200 focus:outline-none focus:ring-1',
  'primary-stroke':
    'border-primary-300 bg-transparent focus:ring-primary-200 focus:outline-none focus:ring-1',
  'primary-ghost':
    'border-transparent bg-transparent focus:ring-primary-200 focus:outline-none focus:ring-1',
  secondary:
    'border-secondary-300 bg-secondary-300 focus:ring-secondary-200 focus:outline-none focus:ring-1',
  'secondary-stroke':
    'border-secondary-300 bg-transparent focus:ring-secondary-200 focus:outline-none focus:ring-1 dark:border-secondary-300 dark:bg-transparent',
  'secondary-ghost':
    'border-transparent bg-transparent focus:ring-secondary-200 focus:outline-none focus:ring-1',
  success:
    'border-success-300 bg-success-300 focus:ring-success-200 focus:outline-none focus:ring-1',
  'success-stroke':
    'border-success-300 bg-transparent focus:ring-success-200 focus:outline-none focus:ring-1',
  'success-ghost':
    'border-transparent bg-transparent focus:ring-success-200 focus:outline-none focus:ring-1',
  error:
    'border-error-300 bg-error-300 focus:ring-error-200 focus:outline-none focus:ring-1',
  'error-stroke':
    'border-error-300 bg-transparent focus:ring-error-200 focus:outline-none focus:ring-1',
  'error-ghost':
    'border-transparent bg-transparent focus:ring-error-200 focus:outline-none focus:ring-1',
  neutral:
    'border-neutral-low-500 bg-neutral-low-500 dark:border-neutral-high-100 dark:bg-neutral-high-100 focus:ring-neutral-low-400 dark:focus:ring-neutral-high-100 focus:outline-none focus:ring-1',
  'neutral-stroke':
    'border-neutral-low-500 bg-transparent dark:border-neutral-high-100 dark:bg-neutral-low-500 focus:ring-neutral-low-400 dark:focus:ring-neutral-high-100 focus:outline-none focus:ring-1',
  'neutral-ghost':
    'border-transparent bg-transparent focus:ring-neutral-low-400 dark:focus:ring-neutral-high-100 focus:outline-none focus:ring-1',
  like: 'border-transparent focus:ring-primary-200 focus:outline-none focus:ring-1',
  liked:
    'border-transparent focus:ring-primary-100 focus:outline-none focus:ring-1',
  dislike:
    'border-transparent focus:ring-primary-100 focus:outline-none focus:ring-1',
  disliked:
    'border-transparent focus:ring-primary-100 focus:outline-none focus:ring-1',
};

const typeHoverStyles = {
  primary:
    'hover:border-primary-500 hover:bg-primary-500 hover:shadow-[0_1px_2px_0_rgba(19,19,19,0.4)]',
  'primary-stroke':
    'hover:border-primary-300 hover:bg-primary-500 hover:shadow-[0_1px_2px_0_rgba(19,19,19,0.4)]',
  'primary-ghost': 'hover:border-transparent hover:bg-transparent ',
  secondary:
    'hover:border-secondary-500 hover:bg-secondary-500 hover:shadow-[0_1px_2px_0_rgba(19,19,19,0.4)]',
  'secondary-stroke':
    'hover:border-secondary-200 hover:bg-secondary-200 hover:shadow-[0_1px_2px_0_rgba(19,19,19,0.4)] dark:hover:border-secondary-500 dark:hover:bg-secondary-500',
  'secondary-ghost': 'hover:border-transparent hover:bg-transparent',
  success:
    'hover:border-success-500 hover:bg-success-500 hover:shadow-[0_1px_2px_0_rgba(19,19,19,0.4)]',
  'success-stroke':
    'hover:border-success-300 hover:bg-success-200 hover:shadow-[0_1px_2px_0_rgba(19,19,19,0.4)]',
  'success-ghost': 'hover:border-transparent hover:bg-transparent',
  error: 'hover:border-error-500 hover:bg-error-500',
  'error-stroke':
    'hover:border-error-300 hover:bg-error-500 hover:shadow-[0_1px_2px_0_rgba(19,19,19,0.4)]',
  'error-ghost': 'hover:border-transparent hover:bg-transparent',
  neutral:
    'hover:border-neutral-low-300 hover:bg-neutral-low-300 dark:hover:border-neutral-high-300 dark:hover:bg-neutral-high-300 dark:hover:shadow-[0_1px_2px_0_rgba(19,19,19,0.4)] ',
  'neutral-stroke':
    'hover:border-neutral-low-500 hover:bg-neutral-high-200 hover:shadow-[0_1px_2px_0_rgba(19,19,19,0.4)] dark:hover:border-neutral-high-100 dark:hover:bg-neutral-low-300 dark:hover:shadow-[0_1px_2px_0_rgba(19,19,19,0.4)]',
  'neutral-ghost':
    'hover:border-transparent hover:bg-neutral-high-200 dark:hover:bg-neutral-low-300',
};

const textAndIconStyles = {
  primary: 'text-neutral-high-100 [&>svg]:text-neutral-high-100',
  'primary-stroke':
    'text-primary-300 [&>svg]:text-primary-300 dark:text-primary-300 dark:[&>svg]:text-primary-300 [&>svg]:hover:text-neutral-high-100 [&>span]:hover:text-neutral-high-100 dark:[&>span]:hover:text-neutral-high-100 dark:[&>svg]:hover:text-neutral-high-100',
  'primary-ghost':
    'text-primary-300 [&>svg]:text-primary-300 [&>span]:hover:text-primary-500 [&>svg]:hover:text-primary-500 dark:text-primary-300 dark:[&>svg]:text-primary-300 dark:[&>span]:hover:text-primary-500 dark:[&>svg]:hover:text-primary-500',
  secondary:
    'text-neutral-high-100 [&>svg]:text-neutral-high-100 hover:text-neutral-high-100 [&>svg]:hover:text-neutral-high-100 [&>span]:hover:text-secondary-500 [&>svg]:hover:text-secondary-500 dark:[&>span]:hover:text-neutral-high-100 dark:[&>svg]:hover:text-neutral-high-100 [&>span]:hover:text-neutral-high-100 [&>svg]:hover:text-neutral-high-100',
  'secondary-stroke':
    'text-secondary-300 [&>svg]:text-secondary-300 dark:text-secondary-300 dark:[&>svg]:text-secondary-300 [&>span]:hover:text-neutral-high-100 dark:[&>span]:hover:text-neutral-high-100 [&>svg]:hover:text-neutral-high-100 dark:[&>svg]:hover:text-neutral-high-100',
  'secondary-ghost':
    'text-secondary-300 [&>svg]:text-secondary-300 dark:text-secondary-300 dark:[&>svg]:text-secondary-300 [&>span]:hover:text-secondary-500 [&>svg]:hover:text-secondary-500 dark:[&>span]:hover:text-secondary-500 dark:[&>svg]:hover:text-secondary-500',
  success: 'text-neutral-high-100 [&>svg]:text-neutral-high-100',
  'success-stroke':
    'text-success-300 [&>svg]:text-success-300 dark:text-success-300 dark:[&>svg]:text-success-300 [&>span]:hover:text-neutral-high-100 [&>svg]:hover:text-neutral-high-100 dark:[&>span]:hover:text-neutral-high-100 dark:[&>svg]:hover:text-neutral-high-100',
  'success-ghost':
    'text-success-300 [&>svg]:text-success-300 dark:text-success-300 dark:[&>svg]:text-success-300 [&>span]:hover:text-success-500 [&>svg]:hover:text-success-500 dark:[&>span]:hover:text-success-500 dark:[&>svg]:hover:text-success-500 ',
  error: 'text-neutral-high-100 [&>svg]:text-neutral-high-100',
  'error-stroke':
    'text-error-300 [&>svg]:text-error-300 dark:text-error-300 dark:[&>svg]:text-error-300 [&>span]:hover:text-neutral-high-100 [&>svg]:hover:text-neutral-high-100 dark:[&>span]:hover:text-neutral-high-100 dark:[&>svg]:hover:text-neutral-high-100',
  'error-ghost':
    'text-error-300 [&>svg]:text-error-300 dark:text-error-300 dark:[&>svg]:text-error-300 [&>span]:hover:text-error-500 [&>svg]:hover:text-error-500 [&>svg]:hover:text-error-500 dark:[&>svg]:hover:text-error-500 dark:[&>span]:hover:text-error-500',
  neutral:
    'text-neutral-high-100 [&>svg]:text-neutral-high-100 dark:text-neutral-low-500 dark:[&>svg]:text-neutral-low-500 dark:[&>span]:hover:text-neutral-low-500 dark:[&>svg]:hover:text-neutral-low-500',
  'neutral-stroke':
    'text-neutral-low-500 [&>svg]:text-neutral-low-500 dark:text-neutral-high-100 dark:[&>svg]:text-neutral-high-100 dark:[&>span]:hover:text-neutral-high-100 dark:[&>svg]:hover:text-neutral-high-100',
  'neutral-ghost':
    'text-neutral-low-500 [&>svg]:text-neutral-low-500 dark:text-neutral-high-300 dark:[&>svg]:text-neutral-high-300 dark:[&>span]:hover:text-neutral-high-300 [&>svg]:hover:text-neutral-low-300 dark:[&>svg]:hover:text-neutral-high-300',
  like: 'text-primary-300 [&>svg]:text-primary-300 dark:text-neutral-high-100 dark:[&>svg]:text-neutral-high-100 [&>span]:hover:text-primary-500 [&>svg]:hover:text-primary-500 dark:[&>svg]:hover:text-primary-200 dark:[&>span]:hover:text-primary-200',
  liked:
    'text-primary-300 [&>svg]:text-primary-300 dark:text-neutral-high-100 dark:[&>svg]:text-neutral-high-100 dark:[&>svg]:hover:text-primary-200 dark:[&>span]:hover:text-primary-200',
  dislike:
    'text-primary-300 [&>svg]:text-primary-300 dark:text-neutral-high-100 dark:[&>svg]:text-neutral-high-100 [&>span]:hover:text-primary-500 [&>svg]:hover:text-primary-500 dark:[&>svg]:hover:text-primary-200 dark:[&>span]:hover:text-primary-200',
  disliked:
    'text-primary-300 [&>svg]:text-primary-300 dark:text-neutral-high-100 dark:[&>svg]:text-neutral-high-100 dark:[&>svg]:hover:text-primary-200 dark:[&>span]:hover:text-primary-200',
};

const sizesStyles = {
  size1: 'p-4 min-size-10 text-base',
  size2: 'p-3 min-size-9 text-base',
  size3: 'p-2 min-size-8 text-base',
  size4: 'p-2 min-size-7 text-sm',
};

const textSizes = {
  size1: 'size1',
  size2: 'size1',
  size3: 'size1',
  size4: 'size2',
};

const Button = forwardRef(
  (
    {
      as = 'button',
      buttonTextClassName,
      children = null,
      className,
      disabled = false,
      full = false,
      htmlType = 'button',
      icon = null,
      iconLeft = null,
      iconRight = null,
      mobileIcon = null,
      size = 'size1',
      type = 'primary',
      upper = false,
      ...rest
    },
    ref
  ) =>
    createElement(
      HTML_MARKUP_OPTIONS[as] || 'button',
      {
        type: as === 'a' ? null : htmlType,
        className: twMerge(
          'flex w-fit min-w-max select-none items-center border-solid justify-center rounded-3 border no-underline transition-all ease-in',
          full && 'w-full',
          disabled &&
            'cursor-not-allowed border-neutral-high-200 bg-neutral-high-200 [&>svg]:text-neutral-low-200 dark:[&>svg]:text-neutral-low-200',
          disabled &&
            HTML_MARKUP_OPTIONS[as] === HTML_MARKUP_OPTIONS.a &&
            'pointer-events-none',
          icon ? iconOnlySizes[size] : sizesStyles[size],
          !disabled && typeStyles[type],
          !disabled && textAndIconStyles[type],
          !disabled && typeHoverStyles[type],
          upper && 'uppercase',
          icon && 'rounded-full',
          mobileIcon && 'rounded-full md:rounded-3',
          className
        ),
        disabled,
        ref,
        ...rest,
      },
      <>
        {iconLeft &&
          cloneElement(iconLeft, {
            size: iconsSizeMap[size],
            className: mobileIcon ? 'hidden md:flex' : 'flex',
            'aria-hidden': true,
          })}
        {mobileIcon &&
          cloneElement(mobileIcon, {
            size: iconsSizeMap[size],
            className: 'flex md:hidden',
            'aria-hidden': true,
          })}
        {icon ? (
          cloneElement(icon, {
            size: iconsSizeMap[size],
            'aria-hidden': true,
            className: mobileIcon ? 'hidden md:flex' : 'flex',
          })
        ) : (
          <Text
            as="span"
            className={twMerge(
              'whitespace-nowrap',
              iconLeft && 'ml-2',
              iconRight && 'mr-2',
              mobileIcon
                ? 'hidden md:block'
                : 'flex items-center justify-center',
              disabled
                ? 'text-neutral-low-200 dark:text-neutral-low-200'
                : textAndIconStyles[type],
              buttonTextClassName
            )}
            size={textSizes[size]}
            bold
          >
            {children}
          </Text>
        )}
        {iconRight &&
          cloneElement(iconRight, {
            size: iconsSizeMap[size],
            'aria-hidden': true,
            className: mobileIcon ? 'hidden md:flex' : 'flex',
          })}
      </>
    )
);

Button.SIZES = SIZES;
Button.TYPES = TYPES;
Button.HTML_MARKUP_OPTIONS = HTML_MARKUP_OPTIONS;
Button.displayName = 'Button';

Button.propTypes = {
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * HTML markup to be rendered
   */
  as: PropTypes.oneOf(['button', 'a']),
  /**
   * Children to be rendered inside component
   */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  /**
   * Renders a disabled button
   */
  disabled: PropTypes.bool,
  /**
   * Button fills the parent component's width
   */
  full: PropTypes.bool,
  /**
   * Renders an icon as children
   */
  icon: PropTypes.object,
  /**
   * Renders an icon on the left side
   */
  iconLeft: PropTypes.object,
  /**
   * Renders an icon on the right side
   */
  iconRight: PropTypes.object,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * `href` HTML attribute used when `as` prop is set to `a`
   */
  href: PropTypes.string,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * `type` HTML attribute, default is `button`
   */
  htmlType: PropTypes.oneOf(['submit', 'reset', 'button']),
  /**
   * Renders Icon as the only children instead of any text on mobile
   */
  mobileIcon: PropTypes.object,
  /**
   * Renders button with one of the properties below
   */
  type: PropTypes.oneOf(TYPES),
  /**
   * Renders button with one of the pre-determined size
   */
  size: PropTypes.oneOf(['size1', 'size2', 'size3', 'size4']),
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Callback function on click
   */
  onClick: PropTypes.func,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Callback function on focus
   */
  onFocus: PropTypes.func,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Callback function on mouse over
   */
  onMouseOver: PropTypes.func,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Callback function on mouse leave
   */
  onMouseLeave: PropTypes.func,
};

export default Button;
