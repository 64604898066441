import PropTypes from 'prop-types';

import Avatar from 'shopper/components/Avatar';

import { getUserAvatarUrl, toUserAvatarAltText } from 'lib/image';
import { isGenieUser, isUserAdmin } from 'lib/user';

const sizePropToImageSizeMap = {
  size1: 108,
  size2: 96,
  size3: 85,
  size4: 60,
  size5: 50,
  size6: 36,
};

const UserAvatar = ({
  description = null,
  // This does not have the same behavior as `Image` component as it was
  // done after `Avatar` was already being used in production. It would imply
  // to have all `Avatar`s with lazy loading, which could impact performance
  // negatively
  loading,
  size = 'size4',
  userId = null,
  userLevel = null,
  userName = null,
  userPhoto = null,
  userTypeName = null,
  ...rest
}) => (
  <Avatar
    admin={isUserAdmin(userTypeName)}
    description={description || toUserAvatarAltText(userName)}
    level={userId && isGenieUser(userId) ? undefined : userLevel}
    loading={loading}
    size={size}
    src={getUserAvatarUrl(userPhoto, sizePropToImageSizeMap[size])}
    {...rest}
  />
);

UserAvatar.propTypes = {
  description: PropTypes.string,
  loading: PropTypes.oneOf(['eager', 'lazy']),
  size: PropTypes.string,
  userId: PropTypes.number,
  userLevel: PropTypes.number,
  userName: PropTypes.string,
  userPhoto: PropTypes.string,
  userTypeName: PropTypes.string,
};

export default UserAvatar;
