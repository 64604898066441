import { useEffect, useRef } from 'react';

const useKeyDown = (
  keyOrKeysArr,
  callback,
  { targetRef = null, enabled = true } = {}
) => {
  const callbackFnRef = useRef(callback);
  const elTargetRef = useRef(null);
  const enabledPrevValue = useRef(enabled);

  useEffect(() => {
    callbackFnRef.current = callback;

    elTargetRef.current = targetRef?.current ? targetRef.current : document;
  }, [callback, targetRef]);

  useEffect(() => {
    const callCallbackFn = (event) => {
      event.preventDefault();
      callbackFnRef.current(event);
    };

    const handleKeyDown = (event) => {
      const { code, key } = event;
      /**
       * `key` is a fallback for browsers who until now have not implemented
       * `code`.
       */
      const keyPressed = code || key;

      if (Array.isArray(keyOrKeysArr)) {
        if (keyOrKeysArr.includes(keyPressed)) {
          callCallbackFn(event);
        }
      }

      if (typeof keyOrKeysArr === 'string') {
        if (keyOrKeysArr === keyPressed) {
          callCallbackFn(event);
        }
      }
    };

    if (!enabled && enabledPrevValue.current) {
      elTargetRef.current.removeEventListener('keydown', handleKeyDown);
    }

    if (enabled) {
      elTargetRef.current.addEventListener('keydown', handleKeyDown);
    }

    enabledPrevValue.current = enabled;

    return () => {
      elTargetRef.current.removeEventListener('keydown', handleKeyDown);
    };
  }, [enabled]);
};

export default useKeyDown;
