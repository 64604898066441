import PropTypes from 'prop-types';

import AutoCompleteProvider from '../../providers/AutoCompleteProvider';
import Root from './Root';

function RootWithProviders({
  children,
  className,
  full = false,
  size = 'size2',
  onSend,
}) {
  return (
    <AutoCompleteProvider size={size}>
      <Root className={className} full={full} onSend={onSend}>
        {children}
      </Root>
    </AutoCompleteProvider>
  );
}

RootWithProviders.propTypes = {
  /**
   * Children to be rendered inside component.
   */
  children: PropTypes.node.isRequired,
  /**
   * AutoComplete fills the parent component's width.
   */
  full: PropTypes.bool.isRequired,
  /**
   * Renders AutoComplete with one of the pre-determined size
   */
  size: PropTypes.string,
  /**
   * Callback function on keydown when is `Enter` or `NumpadEnter`.
   */
  onSend: PropTypes.func,
};

export default RootWithProviders;
