import { useEffect, useLayoutEffect } from 'react';

import { isBrowser } from 'lib/utils';

/**
 * React currently throws a warning when using useLayoutEffect on the server.
 * To get around it, we can conditionally useEffect on the server (no-op) and
 * useLayoutEffect in the browser
 */
const useIsomorphicLayoutEffect = isBrowser() ? useLayoutEffect : useEffect;

export default useIsomorphicLayoutEffect;
