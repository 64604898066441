const FORUM = {
  CATEGORIES_PAGES_GA_EVENT_CATEGORY: 'forum_category',
  FROM_LEGACY_TO_NEW_THREAD_CATEGORIES_SLUGS: [
    { legacySlug: 'artigos', slug: 'artigos-e-materias' },
    { legacySlug: 'bate-papo', slug: 'conversas-livres' },
    { legacySlug: 'compras-e-pos-venda', slug: 'pos-venda-e-lojas' },
    { legacySlug: 'duvidas', slug: 'duvidas-e-sugestoes' },
    { legacySlug: 'melhorias-e-feedback-promobit', slug: 'sobre-o-promobit' },
    { legacySlug: 'reviews', slug: 'analises-e-reviews' },
  ],
  HOME_PAGE_GA_EVENT_CATEGORY: 'forum_home',
  RECENT_THREADS_METADATA: {
    categorySlug: 'recentes',
    categoryTitle: 'Tópicos recentes',
  },
  THREAD: {
    ACTIONS: { DISLIKE: 'dislike', LIKE: 'like', REMOVE: 'remove' },
    DOMAIN: 'thread',
    STATUSES: {
      APPROVED: 'APPROVED',
      REPROVED: 'REPROVED',
      WAITING: 'WAITING',
    },
    TYPES: { DISCUSSION: 'DISCUSSION', QUESTION: 'QUESTION' },
  },
  THREADS_LIMIT: 20,
  THREADS_WORD_PARAM: 'pagina',
};

export default FORUM;
