import { del, get, patch, post, put } from 'lib/request';

export const deleteCouponKeyword = async ({ keywordId }) =>
  del(`/wishlist/coupon/keyword/${keywordId}`);

export const deleteOfferKeyword = async ({ keywordId }, opts) =>
  del(`/wishlist/offer/keyword/${keywordId}`, opts);

export const getCouponAutocompleteKeywords = (opts) =>
  get('/wishlist/coupon/keyword/autocomplete', opts);

export const getOfferKeywordsSuggestions = async ({ genericValue }, opts) =>
  get(`/wishlist/offer/keyword/suggestions/${genericValue}`, opts);

export const getCouponKeywords = (opts) =>
  get('/wishlist/coupon/keyword', opts);

export const getOfferKeywords = (opts) => get('/wishlist/offer/keyword', opts);

export const patchOfferKeywordAdvancedSettings = async ({
  keywordCategoryId,
  keywordDisplay,
  keywordId,
  keywordMax,
  keywordMin,
  keywordTop,
  keywordRecents,
  onlyNational,
}) => {
  const body = {
    keywordMax,
    keywordDisplay,
    keywordTop,
    keywordRecents,
    onlyNational,
  };

  if (keywordMin) {
    body.keywordMin = keywordMin;
  }

  if (keywordCategoryId) {
    body.keywordCategoryId = keywordCategoryId;
  }

  return patch(`/wishlist/offer/keyword/${keywordId}`, { body });
};

export const postAddCouponKeyword = async ({
  keywordStoreId,
  keywordCategoryId,
}) =>
  post('/wishlist/coupon/keyword', {
    body: { keywordStoreId, keywordCategoryId },
  });

export const postAddOfferKeyword = async ({
  keywordDisplay,
  keywordMax,
  checkGeneric = true,
}) => {
  const body = { keywordTherm: keywordDisplay, checkGeneric };

  if (keywordMax) {
    body.keywordMax = keywordMax;
  }

  return post('/wishlist/offer/keyword', {
    body,
  });
};

export const postAddOfferKeywords = async ({ keywords }) =>
  post('/wishlist/offer/keyword/bulk', { body: { keywords } });

export const postAddCouponKeywords = async ({ keywords }) =>
  post('/wishlist/coupon/keyword/bulk', { body: { keywords } });

export const putReceiveCouponEmailsSubscription = async ({ active }) =>
  put('/mail/wishlist/coupon/subscription', {
    body: { active },
  });

export const putReceiveOfferEmailsSubscription = async ({ active }) =>
  put('/mail/wishlist/offer/subscription', {
    body: { active },
  });
