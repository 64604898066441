import { useState } from 'react';

import useScrollPosition from 'hooks/useScrollPosition';

const useTopScrollPosition = () => {
  const [isTopScrollPosition, setTopScrollPosition] = useState(true);

  useScrollPosition(({ currPos }) => {
    setTopScrollPosition(currPos.y === 0);
  });

  return [isTopScrollPosition];
};

export default useTopScrollPosition;
