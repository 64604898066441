import { useQuery } from '@tanstack/react-query';
import dynamic from 'next/dynamic';

import { topbarQueryKeys } from 'lib/queryKeys';

import { useTopbar } from 'providers/TopbarProvider';

import { getTopbarAd } from 'services/ads';

import TopbarDesktop from './Desktop';
import TopbarMobile from './Mobile';

const TopbarAd = dynamic(() => import('./TopbarAd'));

const Topbar = () => {
  const { isTopbarActive } = useTopbar();

  const { data: topbarAd } = useQuery({
    queryKey: topbarQueryKeys.ads(),
    queryFn: ({ signal }) => getTopbarAd({ signal }),
    enabled: false,
    cacheTime: Infinity,
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  if (!isTopbarActive) {
    return null;
  }

  return (
    <>
      {topbarAd && <TopbarAd {...topbarAd} />}
      <header className="sticky top-0 z-40 w-full transition-all duration-150 ease-in lg:mb-9">
        <TopbarDesktop />
        <TopbarMobile />
      </header>
    </>
  );
};

export default Topbar;
