import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

const Anchor = forwardRef(
  ({ className, disabled = false, children, ...rest }, ref) => (
    <a
      ref={ref}
      className={twMerge(
        'no-underline',
        '[&>*]:text-primary-400 [&>*]:hover:text-primary-300 [&>*]:active:text-primary-200 [&>*]:dark:text-primary-200 [&>*]:hover:dark:text-primary-100 [&>*]:active:dark:text-primary-100',
        disabled && 'pointer-events-none',
        className
      )}
      {...rest}
    >
      {children}
    </a>
  )
);

Anchor.displayName = 'Anchor';

Anchor.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default Anchor;
