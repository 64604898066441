import PropTypes from 'prop-types';
import { twJoin } from 'tailwind-merge';

import Heading from '../../Heading';
import Text from '../../Text';

const COUNTER_STYLES = 'text-neutral-low-100 dark:text-neutral-low-100';
const HEADING_SIZES = {
  size1: 'size4',
  size2: 'size4',
  size3: 'size4',
};

const TEXT_SIZES = {
  size4: 'size1',
  size5: 'size2',
  size6: 'size2',
};

const Counter = ({ className, count, size }) => {
  const counter = `+${count}`;

  const NumberComponent =
    HEADING_SIZES[size] || HEADING_SIZES.size1 ? (
      <Heading className={COUNTER_STYLES} size={HEADING_SIZES[size]}>
        {counter}
      </Heading>
    ) : (
      <Text
        className={COUNTER_STYLES}
        size={TEXT_SIZES[size] || TEXT_SIZES.size4}
      >
        {counter}
      </Text>
    );

  return (
    <div
      className={twJoin(
        'flex flex-col items-center justify-center rounded-full bg-neutral-high-300',
        className
      )}
    >
      {NumberComponent}
    </div>
  );
};

Counter.propTypes = {
  count: PropTypes.number.isRequired,
  size: PropTypes.string.isRequired,
};

export default Counter;
