import UniversalSchemas from 'components/Seo/UniversalSchemas';

import GoogleTagManager from './GoogleTagManager';

const GlobalScripts = () => (
  <>
    <GoogleTagManager />
    <UniversalSchemas />
  </>
);

export default GlobalScripts;
