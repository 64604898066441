import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';

import { listen } from 'lib/eventManager';
import { sendEvent } from 'lib/gtag';

export const OPEN_MODAL_EVENT = 'open-modal';
const GA_EVENT_CATEGORY = 'modal';

const ModalRoot = () => {
  const router = useRouter();
  const [Modal, setModal] = useState(null);

  const hideModal = () => {
    setModal(null);
  };

  useEffect(() => {
    const closeModal = () => {
      const dialogElem = document.querySelector('dialog');

      if (dialogElem) {
        hideModal();
      }
    };
    const unlisten = listen(
      OPEN_MODAL_EVENT,
      ({ detail }) => {
        setModal(detail);
      },
      { runLostEmits: true }
    );

    router.events.on('routeChangeStart', closeModal);

    return () => {
      unlisten();
      router.events.off('routeChangeStart', closeModal);
    };
  }, []);

  useEffect(() => {
    const onOverlayClick = (event) => {
      if (event.target instanceof HTMLDialogElement) {
        hideModal();
        sendEvent({
          action: 'click_outside',
          category: GA_EVENT_CATEGORY,
        });
      }
    };

    document.addEventListener('click', onOverlayClick);

    return () => {
      document.removeEventListener('click', onOverlayClick);
    };
  }, []);

  const onCloseHandler = useCallback(() => {
    hideModal();

    if (Modal.props.onClose) {
      Modal.props.onClose();
    }
  }, [Modal]);

  if (!Modal) {
    return null;
  }

  return <Modal.component {...Modal.props} onClose={onCloseHandler} />;
};

export default ModalRoot;
