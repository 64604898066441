import { appendQsParams, del, get, patch, post } from 'lib/request';

export const deleteComment = async ({ commentId }) =>
  del(`/comment/${commentId}`);

export const patchEditComment = async ({ commentId, commentText }) =>
  patch(`/v4/comments/${commentId}`, { body: { text: commentText } });

export const getArticleComments = async (
  { articleId, sort, comment, limit, after, repliesLimit },
  opts
) =>
  get(
    formatCommentsListEndpoint({
      after,
      comment,
      endpoint: `/article/${articleId}/comment`,
      limit,
      repliesLimit,
      sort,
    }),
    opts
  );

export const getArticleCommentMentionsList = async ({ articleId }, opts) =>
  get(`/offer/${articleId}/mentions`, opts);

export const getArticleCommentsCount = async ({ articleId }, opts) =>
  get(`/article/${articleId}/comment/count`, opts);

export const getArticleCommentsTopContributorsList = async (
  { days, limit },
  opts
) =>
  get(
    appendQsParams(`/comment/articles/top-contributors/days/${days}`, {
      limit,
    }),
    opts
  );

export const getArticleLastCommentsList = async ({ page, limit }, opts) =>
  get(appendQsParams('/articles/comments', { page, limit }), opts);

export const getCommentRepliesList = async ({ commentId, after }) =>
  get(appendQsParams(`/comment/${commentId}/replies`, { after }));

export const getCommentTopContributorsList = async ({ days, limit }, opts) =>
  get(
    appendQsParams(`/comment/general/top-contributors/days/${days}`, {
      limit,
    }),
    opts
  );

export const getLastCommentsList = async ({ page, limit }, opts) =>
  get(appendQsParams('/comments', { page, limit }), opts);

export const getOfferCommentsList = async (
  { after, comment, limit, offerId, repliesLimit, sort },
  opts
) =>
  get(
    formatCommentsListEndpoint({
      after,
      comment,
      endpoint: `/offer/${offerId}/comment`,
      limit,
      repliesLimit,
      sort,
    }),
    opts
  );

export const getOfferCommentsCount = async ({ offerId }, opts) =>
  get(`/offer/${offerId}/comment/count`, opts);

export const getOfferCommentMentionsList = async ({ offerId }, opts) =>
  get(`/offer/${offerId}/mentions`, opts);

export const getOfferCommentsTopContributorsList = async (
  { days, limit },
  opts
) =>
  get(
    appendQsParams(`/comment/offers/top-contributors/days/${days}`, {
      limit,
    }),
    opts
  );

export const getOfferLastCommentsList = async ({ page, limit }, opts) =>
  get(appendQsParams('/offers/comments', { page, limit }), opts);

export const getThreadCommentMentionsList = async ({ threadId }, opts) =>
  get(`/offer/${threadId}/mentions`, opts);

const formatCommentsListEndpoint = ({
  endpoint,
  after,
  comment,
  limit,
  repliesLimit,
  sort,
}) =>
  appendQsParams(endpoint, {
    after,
    limit,
    replies_limit: repliesLimit,
    // If is there a hash with the commentId, sort it as the first item
    sort: comment
      ? sort
        ? `comment:${comment},${sort}`
        : `comment:${comment}`
      : sort,
  });

export const getThreadCommentsList = async (
  { after, comment, limit, repliesLimit, sort, threadId },
  opts
) =>
  get(
    formatCommentsListEndpoint({
      after,
      comment,
      endpoint: `/thread/${threadId}/comment`,
      limit,
      repliesLimit,
      sort,
    }),
    opts
  );

export const getThreadCommentsTopContributorsList = async (
  { days, limit },
  opts
) =>
  get(
    `/comment/articles/top-contributors/days/${days}${
      limit ? `?limit=${limit}` : ''
    }`,
    opts
  );

export const getThreadLastCommentsList = async ({ page, limit }, opts) =>
  get(appendQsParams('/threads/comments', { page, limit }), opts);

export const getUserArticleCommentsRatings = async ({ articleId }, opts) =>
  get(`/article/${articleId}/comment/ratings`, opts);

export const getUserCommentsList = async ({ limit, userId, after }, opts) =>
  get(appendQsParams(`/users/${userId}/comments`, { limit, after }), opts);

export const getUserOfferCommentsRatings = async ({ offerId }, opts) =>
  get(`/offer/${offerId}/comment/ratings`, opts);

export const getUserThreadCommentsRatings = async ({ threadId }, opts) =>
  get(`/thread/${threadId}/comment/ratings`, opts);

export const patchCommentReviewRating = async ({ commentId, ratings }) =>
  patch(`/comment/${commentId}/ratings/${ratings}`);

export const postArticleComment = async ({
  articleId,
  parentId,
  commentText,
}) => {
  const body = { commentText };

  if (parentId) {
    body.parentId = parentId;
  }

  return post(`/article/${articleId}/comment`, { body });
};

export const postCommentDislike = async ({ commentId }) =>
  post(`/comment/${commentId}/dislike`);

export const postCommentLike = async ({ commentId }) =>
  post(`/comment/${commentId}/like`);

export const postOfferComment = async ({
  offerId,
  commentText,
  commentStars,
  parentId,
  type = null,
}) => {
  const body = { commentText };

  if (parentId) {
    body.parentId = parentId;
  }

  if (type) {
    body.type = type;
  }

  if (commentStars) {
    body.commentStars = commentStars;
  }

  return post(`/offer/${offerId}/comment`, {
    body,
  });
};

export const postRecommendCommentAsReview = async ({ commentId }) =>
  post(`/comment/${commentId}/recommendation`);

export const postReportComment = async ({ commentId }) =>
  post(`/comment/${commentId}/report`);

export const postThreadComment = async ({
  commentText,
  threadId,
  parentId,
}) => {
  const body = { commentText };

  if (parentId) {
    body.parentId = parentId;
  }

  return post(`/thread/${threadId}/comment`, { body });
};
