import { getFromLocalStorage } from 'lib/localStorage';

import RequestError from 'errors/RequestError';

import { toQueryStringParam } from './array';

import LOCAL_STORAGE from 'constants/localStorage';

const RESPONSE_CODE_FALLBACK = 'UNKNOWN_ERROR';
const RESPONSE_MESSAGE_FALLBACK = 'an unknown error has occurred';

export const appendQsParams = (endpoint, params) => {
  const validParams = [];

  for (const paramKey in params) {
    if (params[paramKey]) {
      validParams.push([paramKey, params[paramKey]]);
    }
  }

  if (validParams.length === 0) {
    return endpoint;
  }

  const qs = [];

  for (const param of validParams) {
    qs.push(
      `${param[0]}=${
        Array.isArray(param[1]) ? toQueryStringParam(param[1]) : param[1]
      }`
    );
  }

  return `${endpoint}?${qs.join('&')}`;
};

const apiRequest = async ({
  method,
  key,
  useDefaultAPI = true,
  ignoreErrors = false,
  body,
  credentials = null,
  headers = {},
  signal,
}) => {
  const auth = getFromLocalStorage(LOCAL_STORAGE.ACCESS_TOKEN)
    ? getFromLocalStorage(LOCAL_STORAGE.ACCESS_TOKEN)
    : null;
  const fetchPath = useDefaultAPI
    ? `${process.env.NEXT_PUBLIC_API_URL}${key}`
    : key;
  const currHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...headers,
  };

  // If Content-Type is specified as `null`, let the browser
  // handle this header automatically
  if (headers['Content-Type'] === null) {
    delete currHeaders['Content-Type'];
  }

  if (auth && credentials !== 'include') {
    currHeaders.Authorization = `Bearer ${auth}`;
  }

  let res = null;
  const fetchArgs = { headers: currHeaders, method, body, signal };

  if (credentials) {
    fetchArgs.credentials = credentials;
  }

  try {
    res = await fetch(fetchPath, fetchArgs);
  } catch {
    return null;
  }

  if (res.ok) {
    try {
      const camelCaseKeys = (await import('camelcase-keys')).default;

      return camelCaseKeys(await res.json(), { deep: true });
    } catch {
      return;
    }
  }

  if (ignoreErrors) {
    return null;
  }

  const data = await res.json();

  if ([401, 403, 404].includes(res.status)) {
    const code =
      data?.message ?? data?.errors?.[0].code ?? RESPONSE_CODE_FALLBACK;

    throw new RequestError({ status: res.status, code });
  }

  let errorMessage = RESPONSE_MESSAGE_FALLBACK;

  if ('message' in data) {
    errorMessage = data.message;
  }

  // TODO palliative solution
  // in future this can be improved via error aggregation https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/AggregateError
  if ('errors' in data) {
    errorMessage = JSON.stringify(data.errors);
  }

  if (res.status >= 500 || isValidStatusCodeToTrackErrors(res.status)) {
    const { captureException } = await import('@sentry/nextjs');

    captureException(new Error(errorMessage), {
      contexts: {
        data: {
          endpoint: key,
          method,
          headers,
        },
      },
    });
  }

  throw new Error(errorMessage);
};

export const del = async (key, options = {}) =>
  apiRequest({ method: 'DELETE', key, ...options });

export const get = async (key, options) =>
  apiRequest({ method: 'GET', key, ...options });

const getBodyWithOptions = async (
  body,
  { stringify = true, toSnake = true }
) => {
  let currBody = !body ? null : body;

  if (toSnake) {
    const snakeCaseKeys = (await import('snakecase-keys')).default;

    currBody = snakeCaseKeys(currBody, { deep: true });
  }

  if (stringify) {
    currBody = JSON.stringify(currBody);
  }

  return currBody;
};

export const getFirstPathFromPathname = (pathname) => {
  const regex = /\/[a-z0-9-]+\//i;

  return pathname.length > 1
    ? pathname.match(regex)?.[0] || `${pathname}/`
    : pathname;
};

export const getLastPathPosition = (str = '') =>
  str.includes('/') ? str.lastIndexOf('/') : 0;

const isValidStatusCodeToTrackErrors = (statusCode) =>
  [405, 406, 408, 413, 414, 415].includes(statusCode);

export const patch = async (
  key,
  { body, stringify, toSnake, ...options } = {}
) =>
  apiRequest({
    method: 'PATCH',
    body: !body ? null : await getBodyWithOptions(body, { stringify, toSnake }),
    key,
    ...options,
  });

export const post = async (
  key,
  { body, stringify, toSnake, ...options } = {}
) =>
  apiRequest({
    method: 'POST',
    body: !body ? null : await getBodyWithOptions(body, { stringify, toSnake }),
    key,
    ...options,
  });

export const put = async (key, { body, stringify, toSnake, ...options } = {}) =>
  apiRequest({
    method: 'PUT',
    body: !body ? null : await getBodyWithOptions(body, { stringify, toSnake }),
    key,
    ...options,
  });

export const isParamsValid = (params) => params && params.length > 0;

export const removeHashFromUrl = (router) => {
  router.replace(router.asPath.replace(/#(.*)/g, ''));
};

export const stripQs = (str) =>
  str.includes('?') ? str.substring(0, str.indexOf('?')) : str;
