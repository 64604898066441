import { appendQsParams, get } from 'lib/request';

import OFFER from 'constants/offer';
import { SEARCH_RESULTS_TYPES } from 'constants/searchResults';

export const getSuggestedSearchKeywordsList = async (
  { withOffers = null } = {},
  opts
) => get(appendQsParams('/search/trends', { app: withOffers }), opts);

export const getSearchAutocompleteKeywords = async ({ query }, opts) =>
  get(`/search/autocomplete${query ? `?q=${query}` : ''}`, opts);

const getSearchResultsListEndpoint = (searchQuery, searchResultsType) => {
  switch (searchResultsType) {
    case SEARCH_RESULTS_TYPES.ACTIVE_OFFERS.PARAM:
      return appendQsParams(
        `/search/result/${SEARCH_RESULTS_TYPES.ACTIVE_OFFERS.QUERY}`,
        { q: searchQuery, offer_status_name: OFFER.STATUSES.APPROVED }
      );
    case SEARCH_RESULTS_TYPES.FINISHED_OFFERS.PARAM:
      return appendQsParams(
        `/search/result/${SEARCH_RESULTS_TYPES.FINISHED_OFFERS.QUERY}`,
        { q: searchQuery, offer_status_name: OFFER.STATUSES.FINISHED }
      );
    case SEARCH_RESULTS_TYPES.ARTICLES.PARAM:
      return appendQsParams(
        `/search/result/${SEARCH_RESULTS_TYPES.ARTICLES.QUERY}`,
        { q: searchQuery }
      );
    case SEARCH_RESULTS_TYPES.COUPONS.PARAM:
      return appendQsParams(
        `/search/result/${SEARCH_RESULTS_TYPES.COUPONS.QUERY}`,
        { q: searchQuery }
      );
    case SEARCH_RESULTS_TYPES.STORES.PARAM:
      return null;
    case SEARCH_RESULTS_TYPES.THREADS.PARAM:
      return appendQsParams(
        `/search/result/${SEARCH_RESULTS_TYPES.THREADS.QUERY}`,
        { q: searchQuery }
      );
    case SEARCH_RESULTS_TYPES.USERS.PARAM:
      return appendQsParams(
        `/search/result/${SEARCH_RESULTS_TYPES.USERS.QUERY}`,
        { q: searchQuery }
      );
    case SEARCH_RESULTS_TYPES.ALIAS.PARAM:
      return appendQsParams(
        `/search/result/${SEARCH_RESULTS_TYPES.ALIAS.QUERY}`,
        { alias: searchQuery }
      );
    default:
      return appendQsParams('/search', { q: searchQuery });
  }
};

export const getSearchResultsList = async (
  {
    searchResultsSort,
    searchResultsType,
    alias,
    aliasHash,
    searchQuery,
    searchResultsPage,
    searchResultsTypeParam,
  },
  opts
) => {
  let result = '';

  if (searchQuery) {
    result = `${getSearchResultsListEndpoint(
      encodeURIComponent(searchQuery),
      searchResultsType.PARAM
    )}`;
  }

  if (alias && aliasHash) {
    result = `${getSearchResultsListEndpoint(alias, 'alias')}&v=${aliasHash}`;
  }

  if (
    searchResultsTypeParam !== SEARCH_RESULTS_TYPES.GENERAL.PARAM &&
    searchResultsSort
  ) {
    result = `${result}&sort=${searchResultsSort}`;
  }

  if (searchResultsPage) {
    result = `${result}&page=${searchResultsPage}`;
  }

  /**
   * resolves edge-case to avoid creation of unnecessary cache when page
   * search-results page is mounting
   */
  if (!result) {
    return new Promise((resolve) => resolve({}), 0);
  }

  return get(result, opts);
};
