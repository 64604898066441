import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import useMediaQuery from 'hooks/useMediaQuery';

import { listen, mergeListeners } from 'lib/eventManager';

import { useTopbar } from 'providers/TopbarProvider';

import TOAST from 'constants/toast';

const Container = dynamic(() => import('./Container'), { ssr: false });

const GAP_BETWEEN_TOPBAR_AND_CONTAINER = 24;
const MAX_ACTIVE_TOASTS = 3;
const INITIAL_CONTAINER_PROPS_STATE = {
  [TOAST.DIRECTIONS.TOP]: {},
  [TOAST.DIRECTIONS.BOTTOM]: {},
};

const createToastModel = (key = null, props) => ({
  id: Math.random(),
  key,
  props,
});

const Root = () => {
  const router = useRouter();
  const { isLg } = useMediaQuery();
  const { topbarDesktop, topbarMobile } = useTopbar();
  const [toasts, setToasts] = useState({
    [TOAST.DIRECTIONS.TOP]: [],
    [TOAST.DIRECTIONS.BOTTOM]: [],
  });
  /**
   * If more "meta" states like these become necessary in the future,
   * she should consider modifying `toasts` structure state instead of creating
   * new states.
   * I.e: `toasts[TOAST.DIRECTIONS.TOP]: {list: [], meta: {...}}`
   */
  const [containerProps, setContainerProps] = useState(
    INITIAL_CONTAINER_PROPS_STATE
  );

  useEffect(() => {
    const showToast = ({ detail: { key = null, ...data } }) => {
      const direction = data.direction || TOAST.DIRECTIONS.BOTTOM;

      if (data.containerProps) {
        setContainerProps((prevState) => {
          const nextState = { ...prevState };
          nextState[direction] = data.containerProps;
          return nextState;
        });
      }

      setToasts((prevState) => {
        const shouldAvoidDuplicates = !!key;
        const activeToastsCount = Object.values(prevState).flat().length;
        const updated = { ...prevState };
        const existingToast = shouldAvoidDuplicates
          ? updated[direction].find((toast) => toast.key === key)
          : null;

        if (
          activeToastsCount === MAX_ACTIVE_TOASTS &&
          (!shouldAvoidDuplicates || existingToast)
        ) {
          return prevState;
        }

        if (shouldAvoidDuplicates && existingToast) {
          updated[direction] = updated[direction].filter(
            (toast) => toast.key !== key
          );
        }

        updated[direction].push(createToastModel(key, data));

        return updated;
      });
    };

    const hideToast = ({ detail: { toastId } }) => {
      setToasts((prevState) => ({
        [TOAST.DIRECTIONS.TOP]: prevState[TOAST.DIRECTIONS.TOP].filter(
          (toast) => toast.id !== toastId
        ),
        [TOAST.DIRECTIONS.BOTTOM]: prevState[TOAST.DIRECTIONS.BOTTOM].filter(
          (toast) => toast.id !== toastId
        ),
      }));
      setContainerProps(() => INITIAL_CONTAINER_PROPS_STATE);
    };

    return mergeListeners(
      listen(TOAST.EVENTS.SHOW_TOAST, showToast),
      listen(TOAST.EVENTS.HIDE_TOAST, hideToast)
    );
  }, []);

  useEffect(() => {
    const hideToasts = () => {
      setToasts({ [TOAST.DIRECTIONS.TOP]: [], [TOAST.DIRECTIONS.BOTTOM]: [] });
    };
    const cleanup = listen(TOAST.EVENTS.HIDE_ALL_TOASTS, hideToasts);

    router.events.on('routeChangeStart', hideToasts);

    return () => {
      cleanup();
      router.events.off('routeChangeStart', hideToasts);
    };
  }, [router]);

  const topbarHeight = isLg ? topbarDesktop.height : topbarMobile.height;

  return (
    <>
      {toasts[TOAST.DIRECTIONS.TOP].length > 0 && (
        <Container
          className="top-0"
          offsetTop={`${topbarHeight + GAP_BETWEEN_TOPBAR_AND_CONTAINER}px`}
          toasts={toasts[TOAST.DIRECTIONS.TOP]}
          {...containerProps[TOAST.DIRECTIONS.TOP]}
        />
      )}
      {toasts[TOAST.DIRECTIONS.BOTTOM].length > 0 && (
        <Container
          className="bottom-4"
          toasts={toasts[TOAST.DIRECTIONS.BOTTOM]}
          {...containerProps[TOAST.DIRECTIONS.BOTTOM]}
        />
      )}
    </>
  );
};

export default Root;
