import { getCookie, getCookies } from 'cookies-next';
import PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import {
  CURRENT_ACTIVE_AB_EXPERIMENTS,
  getExpMetaById,
  getVariantCookieKey,
  setExperimentCookie,
} from 'lib/experiment';

const ExperimentContext = createContext({});

const getClientExperiments = (activeExperiments) =>
  activeExperiments.filter(({ isLoadedOnClient }) => isLoadedOnClient);

const ExperimentProvider = ({ ssrActiveExperiments, children }) => {
  const [isExperimentsInitialized, setExperimentInitialized] = useState(false);
  const [clientActiveExperiments, setActiveExperiments] = useState(false);

  useEffect(() => {
    /**
     * Cookies to experiments are always initialized on client so we can deal
     * with cache efficiently. As the return of all of our SSR pages are cached
     * into a CDN for a dynamic amount of time defined by each route, if the
     * experiment is flagged as `isLoadedOnClient: false` we need a
     * way to communicate to our CDN that every user with an experiment cookie
     * should either hit the server directly or be served a cached version that
     * corresponds with the current activated experiment. This prevents CLS
     * issues while also preserving cache efficiency but also makes the initial
     * request after an experiment is started to not make the experiment variant
     * available.
     */
    const initializeExperiments = () => {
      if (CURRENT_ACTIVE_AB_EXPERIMENTS.length === 0) {
        return;
      }

      for (const experiment of CURRENT_ACTIVE_AB_EXPERIMENTS) {
        const expId = experiment.id;
        const { [getVariantCookieKey(expId)]: experimentCookie } = getCookies();

        if (
          !experimentCookie &&
          getExpMetaById(expId).startDate <= new Date()
        ) {
          setExperimentCookie(expId);
        }
      }
    };

    initializeExperiments();
    setExperimentInitialized(true);
  }, []);

  useEffect(() => {
    if (!isExperimentsInitialized) {
      return;
    }

    const runClientExperiments = () => {
      const expsWithFilledVariants = {};

      getClientExperiments(CURRENT_ACTIVE_AB_EXPERIMENTS).forEach(({ id }) => {
        expsWithFilledVariants[id] = getCookie(getVariantCookieKey(id)) || null;
      });

      setActiveExperiments(expsWithFilledVariants);
    };

    runClientExperiments();
  }, [isExperimentsInitialized]);

  const value = useMemo(() => {
    const ssrExperimentsWithFilledVariants = {};

    if (!ssrActiveExperiments) {
      return clientActiveExperiments || null;
    }

    Object.entries(ssrActiveExperiments).forEach(([id, variant]) => {
      ssrExperimentsWithFilledVariants[id] = variant || null;
    });

    return { ...ssrExperimentsWithFilledVariants, ...clientActiveExperiments };
  }, [clientActiveExperiments, ssrActiveExperiments]);

  return (
    <ExperimentContext.Provider value={value}>
      {children}
    </ExperimentContext.Provider>
  );
};

export const useExperiment = () => useContext(ExperimentContext);

ExperimentProvider.propTypes = {
  ssrActiveExperiments: PropTypes.objectOf(PropTypes.string),
};

export default ExperimentProvider;
