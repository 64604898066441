import { appendQsParams, get } from 'lib/request';

const formatCategoryOrSubcategoryOffersListEndpoint = ({
  after,
  endpoint,
  filters,
  limit,
  sort,
}) => {
  const qs = { after, limit, sort };

  if (filters?.stores.length > 0) {
    qs.stores = filters.stores.map(({ storeId }) => storeId);
  }

  if (filters?.priceRange.min > 0) {
    qs.min = filters.priceRange.min;
  }

  if (filters?.priceRange.max > 0) {
    qs.max = filters.priceRange.max;
  }

  if (filters?.venue) {
    qs.venue = filters.venue;
  }

  return appendQsParams(endpoint, qs);
};

export const getCategoriesList = async (
  { selectedCategories, allCategories } = {},
  opts
) =>
  get(
    appendQsParams(`/category`, {
      categories_selection: selectedCategories,
      categories_full: allCategories,
    }),
    opts
  );

getCategoriesList.fallback = [];

export const getCategoryInfo = async ({ categorySlug }) =>
  get(`/categories/${categorySlug}`);

export const getCategoryOffersList = async (
  { after, filters, categorySlug, limit = 36, sort },
  opts
) =>
  get(
    formatCategoryOrSubcategoryOffersListEndpoint({
      after,
      endpoint: `/categories/${categorySlug}/offers`,
      filters,
      limit,
      sort,
    }),
    opts
  );

export const getSubcategoryInfo = async ({ subcategorySlug }) =>
  get(`/subcategories/${subcategorySlug}`);

export const getSubcategoryOffersList = async ({
  after,
  filters,
  limit = 36,
  sort,
  subcategorySlug,
}) =>
  get(
    formatCategoryOrSubcategoryOffersListEndpoint({
      after,
      endpoint: `/subcategories/${subcategorySlug}/offers`,
      filters,
      limit,
      sort,
    })
  );

export const getCategoryAutocompleteKeywords = async ({ query }, opts) =>
  get(`/category/autocomplete${query ? `?q=${query}` : ''}`, opts);
