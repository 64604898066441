/**
 * TODO: check how that would look if only the white background
 * from topbar loaded at first. On client, validate using the users screen
 * to check what topbar should be loaded. That could potentially be a huge save,
 * specially on mobile.
 */
import TopbarBottom from './TopbarBottom';
import TopbarMain from './TopbarMain';

import TOPBAR from 'constants/topbar';

const Topbar = () => (
  <div className="relative hidden lg:block">
    <TopbarMain gaEventCategory={TOPBAR.GA_EVENT_CATEGORY} />
    <TopbarBottom gaEventCategory={TOPBAR.GA_EVENT_CATEGORY} />
  </div>
);

export default Topbar;
