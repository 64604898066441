import { isObj, isEqual as isObjsEqual } from './object';

const MIN_OBJS_TO_BE_GET_WITH_MOST_OCCURRENCES = 3;

export const findAll = (arr, callback) =>
  arr.reduce(
    (acc, item, index) => (callback(item, index) ? [...acc, item] : acc),
    []
  );

export const getObjWithMostOccurrences = (arr, key) => {
  if (arr.length < MIN_OBJS_TO_BE_GET_WITH_MOST_OCCURRENCES) {
    return arr[0];
  }

  const objKeysWithOccurrences = Object.entries(
    arr.reduce((acc, current) => {
      const currentKeyValue = current[key];

      acc[currentKeyValue] = acc[currentKeyValue]
        ? acc[currentKeyValue] + 1
        : 1;

      return acc;
    }, {})
  );

  const keyWithMostOccurrences = objKeysWithOccurrences.reduce(
    (acc, objKeyWithOccurrences) => {
      return objKeyWithOccurrences[1] >= acc[1] ? objKeyWithOccurrences : acc;
    },
    [null, 0]
  )[0];

  return arr.find((obj) => keyWithMostOccurrences === String(obj[key]));
};

export const insertInArray = ({ array, item, position }) => {
  const currArr = [...array];

  currArr.splice(position, 0, item);

  return currArr;
};

export const isFirstItem = (index) => index === 0;

/**
 * there are some edge cases that this fn not work very well to validate
 * specific array types, like:
 * - bidimensional arrays;
 * - multidimensional arrays;
 */
export const isEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  if (arr1.length === 0) {
    return true;
  }

  if (new Set(arr1).size !== new Set(arr2).size) {
    return false;
  }

  for (const i in arr1) {
    if (arr1[i] !== arr2[i]) {
      if (isObj(arr1[i]) && isObj(arr2[i]) && isObjsEqual(arr1[i], arr2[i])) {
        continue;
      }

      let itemExists = false;

      for (const item of arr2) {
        if (isObj(arr1[i]) && isObj(item) && isObjsEqual(arr1[i], item)) {
          itemExists = true;
          break;
        }

        if (arr1[i] === item) {
          itemExists = true;
          break;
        }
      }

      if (!itemExists) {
        return false;
      }
    }
  }

  return true;
};

export const isLastItem = (arr, index) => arr.length - 1 === index;

export const merge = (first, second, keyToCompare) => {
  if (!Array.isArray(first) && !Array.isArray(second)) {
    return [];
  }

  if (!Array.isArray(first)) {
    return second;
  }

  if (!Array.isArray(second)) {
    return first;
  }

  const merged = [...first, ...second];
  const result = [];

  for (const mergedObj of merged) {
    const exists = result.some(
      (pushedObj) => mergedObj[keyToCompare] === pushedObj[keyToCompare]
    );

    if (exists) {
      continue;
    }

    result.push(mergedObj);
  }

  return result;
};

export const nonNull = (arr) => arr.filter(Boolean);

export const uniqBy = (arr, key) => {
  if (arr.length === 0) {
    return arr;
  }

  if (!isObj(arr[0]) || arr[0][key] === undefined) {
    return arr;
  }

  const result = [];

  for (const item of arr) {
    let itemAlreadyPushed = false;

    for (const pushedItem of result) {
      if (item[key] === pushedItem[key]) {
        itemAlreadyPushed = true;
      }
    }

    if (!itemAlreadyPushed) {
      result.push(item);
    }
  }

  return result;
};

export const stripDuplicates = (arr) => [...new Set(arr)];

export const stripObjDuplicates = (arr, key) =>
  arr.reduce((filter, current) => {
    const firstObjWithKey = filter.find((item) => item[key] === current[key]);

    if (!firstObjWithKey) {
      return filter.concat([current]);
    }

    return filter;
  }, []);

export const toQueryStringParam = (arr) => arr.join(',');
