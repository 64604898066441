import { isEqual as isArraysEqual } from './array';

export const getDepthLevel = ({ obj, key, value }) => {
  const depthLevelsMarker = new Set();

  const getDepthLevelRecursively = (recursiveObj, currDepthLevel = 0) => {
    if (!recursiveObj) {
      return;
    }

    depthLevelsMarker.add(currDepthLevel);

    if (recursiveObj[key] && recursiveObj[key] === value) {
      return;
    }

    for (const recursiveKey in recursiveObj) {
      const currRecursiveObjValue = recursiveObj[recursiveKey];

      if (isObj(currRecursiveObjValue)) {
        getDepthLevelRecursively(currRecursiveObjValue, currDepthLevel + 1);
      }

      if (
        Array.isArray(currRecursiveObjValue) &&
        isObj(currRecursiveObjValue[0])
      ) {
        for (const subkey in currRecursiveObjValue) {
          getDepthLevelRecursively(
            currRecursiveObjValue[subkey],
            currDepthLevel + 1
          );
        }
      }
    }
  };

  getDepthLevelRecursively(obj);

  const result = [...depthLevelsMarker].pop();

  return Number(result);
};

export const getValueFromPath = (obj, path) =>
  [obj].concat(path.split('.')).reduce((prevObj, key) => prevObj[key]);

export const isEmpty = (obj) => Object.keys(obj).length === 0;

export const isEqual = (obj1, obj2) => {
  if (!isObj(obj1) || !isObj(obj2)) {
    return false;
  }

  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false;
  }

  for (const key in obj1) {
    if (obj2[key] === undefined) {
      return false;
    }

    if (isObj(obj1[key])) {
      if (!isObj(obj2[key])) {
        return false;
      }

      return isEqual(obj1[key], obj2[key]);
    }

    if (Array.isArray(obj1[key])) {
      if (Array.isArray(obj2[key]) || obj1[key].length !== obj2[key].length) {
        return false;
      }

      return isArraysEqual(obj1[key], obj2[key]);
    }

    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
};

export const isObj = (item) =>
  item && typeof item === 'object' && !Array.isArray(item);

export const merge = (result, ...sources) => {
  if (!sources.length) {
    return result;
  }

  const source = sources.shift();

  if (isObj(result) && isObj(source)) {
    for (const key in source) {
      if (isObj(source[key])) {
        if (result[key] === undefined) {
          result[key] = {};
        }

        merge(result[key], source[key]);
      } else {
        result[key] = source[key];
      }
    }
  }
  return merge(result, ...sources);
};

export const omit = (obj, props, result = {}) => {
  if (!Array.isArray(props) || props.length === 0) {
    return obj;
  }

  for (const key in obj) {
    if (props.includes(key)) {
      continue;
    }

    if (isObj(obj[key])) {
      if (result[key] === undefined) {
        result[key] = {};
      }

      omit(obj[key], props, result[key]);
    } else {
      result[key] = obj[key];
    }
  }

  return result;
};

export const pick = (obj, props, result = {}) => {
  if (!Array.isArray(props) || props.length === 0) {
    return obj;
  }

  for (const key in obj) {
    if (props.includes(key)) {
      result[key] = obj[key];
      continue;
    }

    if (isObj(obj[key])) {
      if (result[key] === undefined) {
        result[key] = {};
      }

      pick(obj[key], props, result[key]);
    }
  }

  for (const key in result) {
    if (isObj(result[key]) && isEmpty(result[key])) {
      delete result[key];
    }
  }

  return result;
};
