import PropTypes from 'prop-types';
import { twJoin } from 'tailwind-merge';

import Badge from 'shopper/components/Badge';

const AvatarBadge = ({ admin = false, level }) => (
  <Badge
    className={twJoin(
      'absolute -bottom-[0.10rem] -right-[0.30rem] size-4 text-xs'
    )}
    shape="number"
    type={admin ? 'neutral' : 'success'}
  >
    {level}
  </Badge>
);

AvatarBadge.propTypes = {
  admin: PropTypes.bool,
  level: PropTypes.number.isRequired,
};

export default AvatarBadge;
