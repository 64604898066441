import dynamic from 'next/dynamic';

import Input from './components/Input';
import List from './components/List';
import Root from './components/Root';

const AutoComplete = {
  List,
  ListItem: dynamic(() => import('./components/ListItem'), { ssr: false }),
  Root,
  Input,
  Section: dynamic(() => import('./components/Section'), { ssr: false }),
};

export default AutoComplete;
