import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';

import { getRefState } from 'shopper/utils/object';

import useKeyDown from 'hooks/useKeyDown';

import { useAutoComplete } from '../../providers/AutoCompleteProvider';

const Overlay = dynamic(() => import('./Overlay'), {
  ssr: false,
});

const List = dynamic(() => import('./List'), { ssr: false });

function ListWrapper({
  children: render,
  items = [],
  loading = false,
  maxItems = 8,
  overlay = false,
}) {
  const { isTyping, isListOpen, searchInputValue, setListOpen } =
    useAutoComplete();

  const hasPossibleValidItems = Array.isArray(items) && items.length > 0;

  useKeyDown(
    'Escape',
    () => {
      setListOpen(false);
    },
    { enabled: hasPossibleValidItems }
  );

  if (!isListOpen) {
    return null;
  }

  const isLoading =
    loading ||
    (!hasPossibleValidItems &&
      isTyping &&
      getRefState(searchInputValue).length > 3);

  return (
    <>
      {(hasPossibleValidItems || isLoading) && (
        <List
          items={items}
          loading={isLoading}
          maxItems={maxItems}
          render={render}
        />
      )}
      {overlay && <Overlay />}
    </>
  );
}

ListWrapper.propTypes = {
  /**
   * * **DEVELOPMENT USE ONLY**
   *
   * Callback function to render list items.
   */
  children: PropTypes.func.isRequired,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * set list of times to repass to render callback.
   */
  items: PropTypes.array,
  /**
   * Set as loading state.
   */
  loading: PropTypes.bool,
  /**
   * Set max quantity of items should be listed.
   */
  maxItems: PropTypes.number,
  /**
   * Set as overlay state
   */
  overlay: PropTypes.bool,
};

export default ListWrapper;
