import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

import Icon from 'shopper/components/Icon';

import { setRefState } from '../../../utils/object';
import { useAutoComplete } from '../providers/AutoCompleteProvider';

let timer;

function Input({
  as: Component,
  id,
  name,
  placeholder,
  iconRight,
  value,
  onBlur,
  onChange,
  onCleanup,
  onFocus,
  ...rest
}) {
  const { searchInputValue, setListOpen, setTyping, size, isListOpen } =
    useAutoComplete();
  const isSearchInput = Component.displayName === 'SearchInput';

  setRefState(searchInputValue, value);

  const onChangeHandler = (event) => {
    setListOpen(true);
    onChange(event);
  };

  const onFocusHandler = (event) => {
    setListOpen(true);

    if (onFocus) {
      onFocus(event);
    }
  };

  return (
    <Component
      className={twMerge('peer', isListOpen && 'z-60')}
      {...rest}
      iconRight={
        !isSearchInput &&
        (value.length > 0 ? (
          <Icon as="button" name="close" onClick={onCleanup} />
        ) : (
          iconRight
        ))
      }
      id={id}
      name={name}
      placeholder={placeholder}
      size={isSearchInput && size}
      value={value}
      full
      onBlur={onBlur}
      onChange={onChangeHandler}
      onCleanup={isSearchInput && onCleanup}
      onFocus={onFocusHandler}
      onKeyDown={() => {
        setTyping(true);
      }}
      onKeyUp={() => {
        clearTimeout(timer);

        // Debounce to avoid skeleton flicks
        timer = setTimeout(() => {
          setTyping(false);
        }, 300);
      }}
    />
  );
}

Input.propTypes = {
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Repass input component that should be rendered
   */
  as: PropTypes.shape().isRequired,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Custom identifier for the input
   */
  id: PropTypes.string,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Receives HTML attr `name` value
   */
  name: PropTypes.string,
  /**
   * Text that appears in the form control when the it is empty
   */
  placeholder: PropTypes.string.isRequired,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Value of the input
   */
  value: PropTypes.string.isRequired,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Callback fn to be executed when cleanup button is clicked
   */
  onCleanup: PropTypes.func.isRequired,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Callback fn to be executed on `onBlur` event
   */
  onBlur: PropTypes.func,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Callback fn to be executed on `onFocus` event
   */
  onFocus: PropTypes.func,
};

export default Input;
