import {
  QueryClient,
  QueryClientProvider as TanstackQueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import useSingleton from 'hooks/useSingleton';

import { parseToBool } from 'lib/string';

const QueryClientProvider = ({ children }) => {
  const queryClient = useSingleton(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            /**
             * By default we enable automatic queries on component mount,
             * change this when using queries based in event listeners
             */
            enabled: true,
            /**
             * 4 minutes of queries in memory is enough
             * See: https://react-query.tanstack.com/v4/docs/guides/caching
             */
            cacheTime: 4 * 60 * 1000,
            /**
             * 30 seconds for caching without re-fetching on the backend
             * See: https://react-query.tanstack.com/v4/docs/guides/caching
             */
            staleTime: 0.5 * 60 * 1000,
            /**
             * Most of our features probably won't require refetch on windows
             * focus. If it turns out to be the case though, change the default
             * behavior here
             */
            refetchOnWindowFocus: false,
            /**
             * All of our requests uses the same backend, and it's very reliable
             * If a request failed, there is no need to retry
             * It probably REALLY failed and won't recover by itself
             */
            retry: false,
          },
          mutations: {
            /**
             * 4 minutes of queries in memory is enough
             * See: https://react-query.tanstack.com/v4/docs/guides/caching
             */
            cacheTime: 4 * 60 * 1000,
          },
        },
      })
  );

  return (
    <TanstackQueryClientProvider client={queryClient}>
      {children}
      {parseToBool(process.env.NEXT_PUBLIC_REACT_QUERY_DEVTOOLS) && (
        <ReactQueryDevtools
          panelProps={{
            style: {
              height: `${process.env.NEXT_PUBLIC_REACT_QUERY_DEVTOOLS_HEIGHT}px`,
            },
          }}
        />
      )}
    </TanstackQueryClientProvider>
  );
};

export default QueryClientProvider;
