import { getFromLocalStorage } from './localStorage';
import { isFloat } from './number';

import APP from 'constants/app';

export const brlCurrencyMask = (value) => {
  if (!value) {
    return '';
  }

  let currValue = value.toString();

  if (typeof value === 'number' && !isFloat(value)) {
    currValue += '00';
  }

  if (typeof value === 'number' && isFloat(value)) {
    currValue = value.toFixed(2).toString();
  }

  let num = currValue.replace(/\D/g, '');

  num = num.replace(/^0+/, '');

  if (num === '') {
    return 0;
  }

  num = num.length > 2 ? num : '0'.repeat(2 - num.length) + num;
  num = num.split('');

  if (num.length > 5) num.splice(-5, 0, '.');
  if (num.length === 2) num.unshift(0);

  num.splice(-2, 0, ',');

  num = num.join('');

  return num;
};

export const capitalizeEveryWord = (str) => {
  const capitalizedStr = str.toUpperCase();

  return capitalizedStr.split('').reduce((acc, char, i) => {
    if (acc[i - 1] === ' ') {
      return `${acc}${char.toUpperCase()}`;
    }

    return `${acc}${char.toLowerCase()}`;
  });
};

export const getWordAt = (str, position) => {
  const isSpace = (c) => /\s/.exec(c);
  let start = position - 1;
  let end = position;

  while (start >= 0 && !isSpace(str[start])) {
    start -= 1;
  }

  start = Math.max(0, start + 1);

  while (end < str.length && !isSpace(str[end])) {
    end += 1;
  }

  end = Math.max(start, end);

  return { start, end, word: str.slice(start, end) };
};

export const hasUpperCase = (str) => str?.toLowerCase() !== str;

export const hasImageOrVideoHtmlTag = (str) => /<(img|iframe)/i.test(str);

export const isEmailValid = (email) =>
  /^[^\s\t\r\n]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i.test(email);

export const isHtml = (str) => /<\/?[a-z][\s\S]*>/i.test(str);

export const isUrlValid = (url) =>
  /^http(s?):\/\/[a-z0-9-_.]+\.[a-z]+/gi.test(url);

export const isUrlImageValid = (url) =>
  /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|jpeg|gif|png|webp)/g.test(url);

export const isUrlValidForInternalUsage = (url = '') =>
  url?.includes(APP.SHORTENED_URL);

export const isPhoneValid = (phone) => removeNonDigits(phone).length === 11;

export const isTwitterUrlValid = (url = '') =>
  /(http(s)?:\/\/)?(www\.)?twitter\.com/.test(url);

export const isYoutubeUrlValid = (url = '') =>
  /(https?:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/.test(url);

export const parseToBool = (str) => {
  if (!str) {
    return false;
  }

  if (str && typeof str === 'boolean') {
    return true;
  }

  const lowerStr = str.trim().toLowerCase();

  return ['1', 'true', 'yes'].includes(lowerStr);
};

export const parseToHtml = (str) => {
  const parser = new DOMParser();

  return parser.parseFromString(str, 'text/html');
};

export const phoneMask = (phone) => {
  if (!phone) {
    return '';
  }

  const phoneDigits = removeNonDigits(phone);

  return phoneDigits
    .replace(/^(\d)/, '($1')
    .replace(/^(\(\d{2})(\d)/, '$1) $2')
    .replace(/(\d{5})(\d{1,4})/, '$1-$2')
    .replace(/(-\d{4})\d+?$/, '$1');
};

export const removeNonDigits = (str) => str.replace(/\D/g, '');

export const removeAllWhiteSpaceChars = (str) => str?.replace(/\s+/g, ' ');

export const sliceWithEllipsis = (str, maxLength) => {
  if (str?.length >= maxLength) {
    return `${str.substring(0, maxLength).trim()}...`;
  }

  return str;
};

/**
 * this fn format the GA Event Action prefix to a string who is able to be used
 * in already existing GA Event Actions and that they cannot be changed.
 */
export const toFormattedGaEventActionPrefix = (gaEventActionPrefix) =>
  gaEventActionPrefix ? `${gaEventActionPrefix}_` : '';

export const toFormattedJsonLdString = (string) =>
  JSON.stringify(string).replace(/(^"|"$)/g, '');

export const toFormattedRankingPosition = (positionNumber) =>
  `${positionNumber}º`;

export const toTabCount = (lastTabItemId, localStorageTabItemId) => {
  if (!lastTabItemId || !localStorageTabItemId) {
    return null;
  }

  const count =
    lastTabItemId - Number(getFromLocalStorage(localStorageTabItemId));

  if (count <= 0) {
    return null;
  }

  if (count > 99) {
    return '+99';
  }

  return count;
};

export const removeWhitespaceBetweenCharacters = (string) =>
  string.replace(/ +/g, '');
