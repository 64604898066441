import PropTypes from 'prop-types';
import { createElement, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

const SIZES = ['size1', 'size2', 'size3'];
const HTML_MARKUP_OPTIONS = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  p: 'p',
  article: 'article',
  div: 'div',
  em: 'em',
  span: 'span',
  a: 'a',
  button: 'button',
  label: 'label',
  time: 'time',
  figcaption: 'figcaption',
  li: 'li',
  b: 'b',
  strong: 'strong',
};

const sizeStyles = {
  size1: 'text-base',
  size2: 'text-sm',
  size3: 'text-xs',
};

const Text = forwardRef(
  (
    {
      as = 'p',
      bold = false,
      className,
      children = null,
      italic = false,
      upper = false,
      size = 'size1',
      ...rest
    },
    ref
  ) =>
    createElement(
      HTML_MARKUP_OPTIONS[as] || 'p',
      {
        className: twMerge(
          'font-regular font-sans text-neutral-low-400 dark:text-neutral-high-200 whitespace-pre-wrap',
          bold && 'font-bold',
          italic && 'italic',
          upper && 'uppercase',
          sizeStyles[size],
          className
        ),
        ref,
        ...rest,
      },
      children
    )
);

Text.HTML_MARKUP_OPTIONS = HTML_MARKUP_OPTIONS;
Text.SIZES = SIZES;

Text.displayName = 'Text';

Text.propTypes = {
  /**
   * HTML markup to be rendered
   */
  as: PropTypes.oneOf(Object.keys(HTML_MARKUP_OPTIONS)),
  /**
   * Renders a bold text
   */
  bold: PropTypes.bool,
  /**
   * String to be rendered as text
   */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Renders a italic text
   */
  italic: PropTypes.bool,
  /**
   * Renders a text in uppercase
   */
  upper: PropTypes.bool,
  /**
   * Renders a text with a determined size
   */
  size: PropTypes.oneOf(SIZES),
};

export default Text;
