// TODO add default `limit` param in all services fns
import { appendQsParams, del, get, patch, post, put } from 'lib/request';

const LEGACY_API_USER_ME_ENDPOINT = `https://${process.env.NEXT_PUBLIC_SUBDOMAIN}.promobit.com.br/User/me/`;

export const deleteUnfollowUser = async ({ userId }) =>
  del(`/user/${userId}/follow`);

export const getLegacyUserSession = async ({ token }, opts) =>
  get(appendQsParams(LEGACY_API_USER_ME_ENDPOINT, { token }), {
    credentials: 'include',
    headers: { 'Content-Type': null },
    useDefaultAPI: false,
    ...opts,
  });

export const getLGPDConsentsList = (opts) => get('/consents', opts);

export const getLGPDUserConsentsList = (opts) => get('/users/consents', opts);

export const getReferredUsersByUserId = ({ userId, limit, page }) =>
  get(appendQsParams(`/v4/users/${userId}/guests`, { limit, page }));

export const getUserAcquiredBadgesList = async ({ limit, userUsername }) =>
  get(
    `/users/${userUsername}/acquired-badges${limit ? `?limit=${limit}` : ''}`
  );

export const getUserInfo = async ({ userUsername }) =>
  get(`/users/${userUsername}`);

export const getUserFollowingList = (opts) => get('/user/follows', opts);

export const getUserFollowersListByUserId = async (
  { userId, limit, after },
  opts
) => get(appendQsParams(`/users/${userId}/followers`, { limit, after }), opts);

export const getUserFollowingListByUserId = async (
  { userId, limit, after },
  opts
) => get(appendQsParams(`/users/${userId}/following`, { limit, after }), opts);

export const getUserOffers = async (
  { userId = null, userUsername = null, limit, after },
  opts
) =>
  get(
    appendQsParams(`/users/${userId || userUsername}/offers`, {
      limit,
      after,
    }),
    opts
  );

export const getUserProfileInfo = async () => get('/user/me');

export const getUserReviewsList = async ({ userId, limit, after }, opts) =>
  get(appendQsParams(`/users/${userId}/reviews`, { limit, after }), opts);

export const getUserThreadsList = async ({ userId, limit, after }) =>
  get(appendQsParams(`/users/${userId}/threads`, { limit, after }));

export const getUserUnearnedBadgesList = async ({ userUsername, limit }) =>
  get(appendQsParams(`/users/${userUsername}/unacquired-badges`, { limit }));

export const getPasswordRecoveryHashValidation = async ({
  recoveryHash,
  userEmail,
}) => get(`/user/password-recovery/${recoveryHash}/${userEmail}`);

getPasswordRecoveryHashValidation.fallback = { isHashValid: false };

export const patchUserProfile = async ({
  currentPassword,
  newPassword,
  offerViewMode,
  userEmail,
  userName,
  userPhoto,
  userUsername,
}) => {
  let body = {};

  if (offerViewMode) {
    body.offerViewMode = offerViewMode;
  }

  if (userUsername) {
    body.userUsername = userUsername;
  }

  if (userName) {
    body.userName = userName;
  }

  if (userPhoto) {
    body.userPhoto = userPhoto;
  }

  if (userEmail) {
    body.userEmail = userEmail;
  }

  if (currentPassword) {
    body.currentPassword = currentPassword;
  }

  if (newPassword) {
    body.newPassword = newPassword;
  }

  return patch('/user', { body });
};

export const postChangeForgottenPassword = async ({
  recoveryHash,
  userEmail,
  password,
}) =>
  post(`/user/password-recovery/${recoveryHash}/${userEmail}`, {
    body: { password },
  });

export const postFollowUser = async ({ userId }) =>
  post(`/user/${userId}/follow`);

export const postLGPDConsents = async ({ consents }) =>
  post('/consent', { body: consents });

export const postPreSignUpUser = async ({ name, email }) =>
  post('/user/pre-signup', { body: { name, email } });

// Always set `rememberBe` for keeping the token active for a longer period
export const postSignIn = async ({
  email,
  password,
  oauthToken,
  oauthDomain,
  oauthPlatform,
  utmCampaign,
}) => {
  const body = {
    email,
    password,
    oauthToken,
    oauthDomain,
  };

  if (email && password) {
    body.rememberMe = true;
  }

  if (utmCampaign) {
    body.utmCampaign = utmCampaign;
  }

  if (oauthPlatform) {
    body.oauthPlatform = oauthPlatform;
  }

  return post('/token', {
    body,
  });
};

export const postSignUpUser = async ({
  name,
  email,
  password,
  utm_source,
  utm_campaign,
  utm_content,
  utm_medium,
}) =>
  post('/user', {
    body: {
      name,
      email,
      password,
      utm_source,
      utm_campaign,
      utm_content,
      utm_medium,
    },
  });

export const postRequestPasswordRecovery = async ({ email }) =>
  post('/user/passwordRecovery', { body: { email } });

export const putValidateUserEmail = ({ hash }) =>
  put(`/v4/users/me/mail-verification/${hash}`);
