const APP = {
  COMMERCIAL_EMAIL: 'comercial@promobit.com.br',
  CTX_OPTIONAL_PROPS: '__app-ctx-optional-props',
  DEFAULT_LOCALE: 'pt_BR',
  FAVICON: '/dist/favicon.ico',
  LOCAL_IMAGE_PATH: '/dist/images',
  NAME: 'Promobit',
  REMOTE_IMAGE_PATH: 'https://i.promobit.com.br',
  URL: 'https://www.promobit.com.br',
  SHORTENED_URL: 'promobit.com.br',
};

export default APP;
