import PropTypes from 'prop-types';

import TopbarBottomNav from './TopbarBottomNav';

const TopbarBottom = ({ gaEventCategory }) => (
  <div className="absolute right-0 flex w-full border-b border-neutral-high-200 bg-neutral-high-100 px-7 py-2 shadow-md transition-[bottom] duration-300 ease-in dark:border-neutral-low-300 dark:bg-neutral-low-500">
    <nav className="mx-auto flex items-center md:w-full 3xl:w-[120rem]">
      <TopbarBottomNav gaEventCategory={gaEventCategory} />
    </nav>
  </div>
);

TopbarBottom.propTypes = {
  gaEventCategory: PropTypes.string.isRequired,
};

export default TopbarBottom;
