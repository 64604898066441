import { useQuery } from '@tanstack/react-query';
import dynamic from 'next/dynamic';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';

import Badge from 'shopper/components/Badge';
import { TabNav } from 'shopper/components/TabNav';

import Anchor from 'components/Anchor';

import useDialog from 'hooks/useDialog';
import useModal from 'hooks/useModal';

import { getFromLocalStorage, setLocalStorage } from 'lib/localStorage';
import placeholder from 'lib/placeholder';
import { notificationQueryKeys } from 'lib/queryKeys';
import { stripQs } from 'lib/request';
import { noop } from 'lib/utils';

import { useUser } from 'providers/UserProvider';

import { getUnreadNotificationTypesCount } from 'services/notification';

import MobileNavbarTabNavItem from './MobileNavbarTabNavItem';

import LINKS from 'constants/links';
import LOCAL_STORAGE from 'constants/localStorage';

const LoginDialog = dynamic(() => import('components/LoginDialog'), {
  ssr: false,
});

const WishlistModal = dynamic(
  () => import('components/Wishlist/WishlistModal'),
  { ssr: false }
);

const NavbarMobile = () => {
  const router = useRouter();
  const { showModal } = useModal();
  const { showDialog } = useDialog();
  const { isLoggedIn } = useUser();
  const [hasClicked, setHasClicked] = useState(false);

  const { data: notificationCounters, isSuccess } = useQuery({
    queryKey: notificationQueryKeys.counts(),
    queryFn: ({ signal }) => getUnreadNotificationTypesCount({ signal }),
    enabled: isLoggedIn,
    refetchOnWindowFocus: true,
  });

  useEffect(() => {
    const clicked = getFromLocalStorage(
      LOCAL_STORAGE.IS_BLACK_FRIDAY_BUTTON_ALREADY_CLICKED_MOBILE
    );

    if (clicked) {
      setHasClicked(true);
    }
  }, []);

  const onBlackFridayClick = () => {
    if (!hasClicked) {
      setHasClicked(true);

      setLocalStorage(
        LOCAL_STORAGE.IS_BLACK_FRIDAY_BUTTON_ALREADY_CLICKED_MOBILE,
        'true'
      );
    }
  };

  const onWishlistClick = () => {
    showModal(WishlistModal);
  };

  const TABS = useMemo(
    () => [
      {
        gaEventAction: 'offers_click_mobile',
        href: LINKS.HOME,
        iconName: 'promobit-filled',
        title: 'Ofertas',
        onClick: noop,
      },
      {
        gaEventAction: 'wishlist_click_mobile',
        href: false,
        iconName: 'heart-filled',
        title: placeholder('actions.openWishlist'),
        onClick: onWishlistClick,
      },
      {
        gaEventAction: 'notifications_click_mobile',
        href: isLoggedIn ? LINKS.NOTIFICATIONS : false,
        iconName: 'notification-filled',
        title: placeholder('actions.openNotifications'),
        onClick: !isLoggedIn
          ? () => {
              showDialog(LoginDialog);
            }
          : noop,
      },
      {
        gaEventAction: 'suggest_offer_click_mobile',
        href: LINKS.NEW_OFFER,
        iconName: 'add-circle',
        title: placeholder('actions.suggestOffer'),
        onClick: noop,
      },
      {
        gaEventAction: 'featured_landing_tab_mobile',
        href: LINKS.BLACK_FRIDAY,
        iconName: 'star',
        title: placeholder('titles.blackFriday'),
        onClick: onBlackFridayClick,
      },
    ],
    [isLoggedIn, hasClicked]
  );

  return (
    <TabNav className="fixed bottom-0 left-0 z-20 h-13 w-full items-start justify-between border-none bg-neutral-high-100 px-1 shadow-[0_2px_4px_0_rgba(19,19,19,0.5)] dark:bg-neutral-low-500 lg:hidden">
      {TABS.map(({ href, iconName, title, gaEventAction, onClick }) => {
        const TabNavItem = (
          <>
            <MobileNavbarTabNavItem
              key={!href ? title : undefined}
              className="relative"
              gaEventAction={gaEventAction}
              href={href}
              iconName={iconName}
              isActive={stripQs(router.asPath) === href}
              title={title}
              withCounter={
                href === LINKS.NOTIFICATIONS &&
                isSuccess &&
                notificationCounters?.countAll > 0
              }
              onClick={onClick}
            />
            {href === LINKS.BLACK_FRIDAY && !hasClicked && (
              <Badge
                className="absolute right-6 top-3"
                shape="dot"
                type="highlight"
              />
            )}
          </>
        );

        if (href) {
          return (
            <div key={title} className="flex h-full w-1/4 items-center">
              <NextLink key={title} href={href} prefetch={false} passHref>
                <Anchor
                  className="flex h-full flex-1 items-center"
                  /**
                   * "nofollow" is here as these links are already present in
                   * other places like the `Topbar` component,
                   * so we prevent bots from marking these as duplicated links
                   */
                  rel="nofollow"
                  onClick={onClick}
                >
                  {TabNavItem}
                </Anchor>
              </NextLink>
            </div>
          );
        }

        return (
          <div key={title} className="flex h-full w-1/4 items-center">
            {TabNavItem}
          </div>
        );
      })}
    </TabNav>
  );
};

export default NavbarMobile;
