import { appendQsParams, get, post } from 'lib/request';

export const getNotificationsList = async ({ limit, type, after }, opts) =>
  get(appendQsParams('/notification', { limit, type, after }), opts);

export const getUnreadNotificationTypesCount = (opts) =>
  get('/notification/count/types', opts);

export const postMarkAllNotificationsAsRead = async ({ type }) =>
  post(appendQsParams('/notification/read', { type }));

export const postMarkNotificationAsRead = async ({ notificationId }) =>
  post(`/notification/${notificationId}/read`);
