/**
 * Despite this hook being named as "singleton", its behavior is a bit
 * different if compared to the singleton pattern. In the singleton pattern we
 * make the complete application interact with the same instance of a class.
 * Using the useSingleton we make all renders of a component interact with the
 * same instance of a class for classes that cannot be instantiated outside a
 * component likely due to SSR issues or by being a browser-only class.
 */
import { useState } from 'react';

const useSingleton = (fn) => useState(fn)[0];

export default useSingleton;
