const TOAST = {
  DIRECTIONS: {
    TOP: 'top',
    BOTTOM: 'bottom',
  },
  EVENTS: {
    SHOW_TOAST: 'show_toast',
    HIDE_TOAST: 'hide_toast',
    HIDE_ALL_TOASTS: 'hide_toasts',
  },
};

export default TOAST;
