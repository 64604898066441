import { useEffect, useState } from 'react';

import { listen } from 'lib/eventManager';
import {
  getFromLocalStorage,
  getSynchronizingEventKey,
  setLocalStorage,
} from 'lib/localStorage';
import { parseToBool } from 'lib/string';
import { isDev } from 'lib/utils';

const useLocalStorageState = (key, initialValue = null) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const localStorageValue = getFromLocalStorage(key);

      if (typeof initialValue === 'boolean') {
        return localStorageValue
          ? parseToBool(JSON.parse(localStorageValue))
          : initialValue;
      }

      return localStorageValue ? JSON.parse(localStorageValue) : initialValue;
    } catch (error) {
      if (isDev()) {
        // eslint-disable-next-line no-console
        console.error(error);
      }

      return initialValue;
    }
  });

  useEffect(() => {
    const unlisten = listen(getSynchronizingEventKey(key), () => {
      const mostRecentValue = localStorage.getItem(key);

      if (typeof initialValue === 'boolean') {
        setStoredValue(parseToBool(JSON.parse(mostRecentValue)));
        return;
      }

      setStoredValue(JSON.parse(mostRecentValue));
    });

    return () => {
      unlisten();
    };
  }, [key]);

  const setValue = (value) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;

      setStoredValue(valueToStore);
      setLocalStorage(key, JSON.stringify(valueToStore));
    } catch (error) {
      if (isDev()) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  };

  return [storedValue, setValue];
};

export default useLocalStorageState;
