const NOTIFICATION = {
  ORIGINS: {
    ARTICLE: 'ARTICLE',
    COUPON: 'COUPON',
    MESSAGE: 'MESSAGE',
    OFFER: 'OFFER',
    THREAD: 'THREAD',
  },
  TYPES: {
    MESSAGE: 'MESSAGE',
    NOTIFICATION: 'NOTIFICATION',
    WISHLIST: 'WISHLIST',
  },
};

export default NOTIFICATION;
