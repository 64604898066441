import {
  getBestOffersList,
  getOffersList,
  getRecentOffersList,
  getTopOffersList,
} from 'services/offer';

import LINKS from './links';

const LIMIT = 24;

const HOME_OFFERS_LIST_TABS = [
  {
    description: 'Uma seleção das melhores ofertas ativas no Promobit',
    fetcher: ({ after, before, filters }, opts) =>
      getOffersList(
        {
          after,
          before,
          filters,
          limit: LIMIT,
        },
        opts
      ),
    href: LINKS.HOME,
    iconName: 'bolt',
    id: 'highlights',
    title: 'Destaques',
  },
  {
    description: 'Todas as ofertas do Promobit em ordem cronológica.',
    fetcher: ({ after, before, filters }, opts) =>
      getRecentOffersList(
        {
          after,
          before,
          filters,
          limit: LIMIT,
        },
        opts
      ),
    href: LINKS.RECENT_OFFERS,
    iconName: 'clock-filled',
    id: 'recents',
    title: 'Recentes',
  },
  {
    description:
      'Uma seleção das melhores ofertas de acordo com o engajamento dos usuários do Promobit',
    fetcher: ({ after, before, filters }, opts) =>
      getBestOffersList(
        {
          after,
          before,
          filters,
          limit: LIMIT,
        },
        opts
      ),
    href: LINKS.TREND_OFFERS,
    iconName: 'thermostat',
    id: 'trend',
    title: 'Em alta',
  },
  {
    description:
      'Uma seleção de produtos no Promobit com o menor preço nos últimos 3 meses',
    fetcher: ({ after, before, filters }, opts) =>
      getTopOffersList(
        {
          after,
          before,
          filters,
          limit: LIMIT,
        },
        opts
      ),
    href: LINKS.LOW_PRICE_OFFERS,
    iconName: 'trending-down',
    id: 'low_price',
    title: 'Menor preço',
  },
];

export default HOME_OFFERS_LIST_TABS;
