import PropTypes from 'prop-types';
import { createElement, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

const HTML_MARKUP_OPTIONS = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  a: 'a',
  p: 'p',
  span: 'span',
};

const HEADING_STYLES = {
  size1: 'font-bold lg:text-5xl text-3xl lg:-tracking-[.03125rem]',
  size2: 'text-2xl font-bold tracking-normal lg:text-4xl',
  size3: 'text-xl font-bold tracking-normal lg:text-3xl',
  size4: 'text-lg font-bold lg:text-2xl',
  size5: 'text-base font-bold lg:text-xl',
  size6: 'text-sm font-bold lg:text-lg',
};

const getMarkupFromSizeProp = (size) => {
  const headingNumber = Number(size.substring(size.length - 1, size.length));

  if (headingNumber < 0 && headingNumber > 6) {
    return null;
  }

  return `h${headingNumber}`;
};

const Heading = forwardRef(
  ({ as = null, children, className, size = 'size1', ...rest }, ref) =>
    createElement(
      HTML_MARKUP_OPTIONS[as] || getMarkupFromSizeProp(size),
      {
        className: twMerge(
          'text-neutral-low-400 dark:text-neutral-high-200 font-sans',
          HEADING_STYLES[size],
          className
        ),
        ref,
        ...rest,
      },
      children
    )
);

Heading.displayName = 'Heading';

Heading.propTypes = {
  /**
   * HTML markup to be rendered
   */
  as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'span', 'a', 'p']),
  /**
   * Text to be rendered as a `Heading`
   */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  /**
   * Renders `Heading` in one of the following sizes visually and semantically.
   *
   * Can be semantically overwritten by passing `as` prop
   */
  size: PropTypes.oneOf(['size1', 'size2', 'size3', 'size4', 'size5', 'size6']),
};

export default Heading;
