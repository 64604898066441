import PropTypes from 'prop-types';

import DialogRoot from 'components/Dialog/Root';
import DynamicallyLoadedDownloadAppAlertDialog from 'components/DownloadAppAlertDialog';
import DynamicallyLoadedLGPDAlertDialog from 'components/LGPDAlertDialog';
import ModalRoot from 'components/ModalRoot';
import NavbarMobile from 'components/NavbarMobile';
import ScrollControlRoot from 'components/ScrollControlRoot';
import DynamicallyLoadedShareCampaignLoginModal from 'components/ShareCampaignLoginModal/DynamicallyLoadedShareCampaignLoginModal';
import ToastRoot from 'components/Toast/Root';
import Topbar from 'components/Topbar';
import WishlistModalOnUrlHash from 'components/Wishlist/WishlistModal/WishlistModalOnUrlHash';

const GlobalLayout = ({ isRecoveryPasswordValid = false, children }) => (
  <>
    <Topbar />
    <NavbarMobile />
    {children}
    <ToastRoot />
    <DynamicallyLoadedShareCampaignLoginModal
      hasOtherModalWithHigherPriorityOpen={isRecoveryPasswordValid}
    />
    <ScrollControlRoot />
    <DynamicallyLoadedLGPDAlertDialog />
    <DynamicallyLoadedDownloadAppAlertDialog />
    <DialogRoot />
    <ModalRoot />
    <WishlistModalOnUrlHash />
  </>
);

GlobalLayout.propTypes = {
  isRecoveryPasswordValid: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default GlobalLayout;
