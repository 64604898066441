import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';

import { removeFromLocalStorage } from 'lib/localStorage';

import useLocalStorageState from './useLocalStorageState';

import LOCAL_STORAGE from 'constants/localStorage';

const OPTIONS = {
  light: 'light',
  dark: 'dark',
};

const BLACK_FRIDAY_PATH = '/black-friday';

const resetUserThemePreference = () => {
  removeFromLocalStorage(LOCAL_STORAGE.IS_DARK_MODE_ACTIVE);
};

const useTheme = () => {
  const { pathname } = useRouter();
  const [isDarkModeActive, setDarkModeActive] = useLocalStorageState(
    LOCAL_STORAGE.IS_DARK_MODE_ACTIVE,
    false
  );
  const isBlackFridayPage = pathname.includes(BLACK_FRIDAY_PATH);

  const currTheme =
    isBlackFridayPage || isDarkModeActive ? OPTIONS.dark : OPTIONS.light;

  // TODO: implement BroadcastChannel to update theme for all open tabs
  const setTheme = (newTheme) => {
    if (isBlackFridayPage && newTheme === OPTIONS.light) return;

    document.documentElement.classList.remove(currTheme);
    document.documentElement.classList.add(newTheme);
    setDarkModeActive(newTheme === OPTIONS.dark);
  };

  useEffect(() => {
    if (isBlackFridayPage) {
      document.documentElement.classList.remove(OPTIONS.light);
      document.documentElement.classList.add(OPTIONS.dark);
      return;
    }

    document.documentElement.classList.remove(OPTIONS.dark);
    document.documentElement.classList.add(OPTIONS.light);
    setTheme(isDarkModeActive ? OPTIONS.dark : OPTIONS.light);
  }, [isBlackFridayPage, setTheme, pathname]);

  const changeThemeToStoredUserPreference = useCallback(() => {
    if (!isBlackFridayPage) {
      setTheme(isDarkModeActive ? 'dark' : 'light');
    }
  }, [setTheme, isBlackFridayPage, pathname]);

  return {
    options: OPTIONS,
    theme: currTheme,
    changeThemeToStoredUserPreference,
    resetUserThemePreference,
    setTheme,
  };
};

export default useTheme;
