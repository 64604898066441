/**
 * A helper to get a value from sessionStorage
 * Returns the value, or null if no value exists or if storage is unavailable
 */
export const getFromSessionStorage = (key) => {
  try {
    return window.sessionStorage.getItem(key);
  } catch {
    return null;
  }
};

export const getUtmsFromSession = () => {
  // TODO adequate obj keys to camelCase
  const utms = {
    utm_source: getFromSessionStorage('utm_source'),
    utm_campaign: getFromSessionStorage('utm_campaign'),
    utm_content: getFromSessionStorage('utm_content'),
    utm_medium: getFromSessionStorage('utm_medium'),
  };

  const result = {};

  for (const key in utms) {
    const value = utms[key];

    if (value) {
      result[key] = value;
    }
  }

  return result;
};

/**
 * A helper to set a value in sessionStorage
 * Ignores errors about full, disallowed or unsupported storage
 */
export const setSessionStorage = (key, value) => {
  try {
    window.sessionStorage.setItem(key, value);
  } catch {
    // Ignore errors
  }
};

/**
 * A helper to remove an entry in sessionStorage
 * Ignores errors about full, disallowed or unsupported storage
 */
export const removeFromSessionStorage = (key) => {
  try {
    window.sessionStorage.removeItem(key);
  } catch {
    // Ignore errors
  }
};
