import { useRef } from 'react';

import { isBrowser } from 'lib/utils';

import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect';

const WAIT = 800;

const getScrollPosition = () => {
  if (!isBrowser()) return { x: 0, y: 0 };

  return { x: window.scrollX, y: window.scrollY };
};

const useScrollPosition = (
  effect,
  { deps = [], enabled = true, runOnPageLoad = false } = {
    deps: [],
    enabled: true,
    runOnPageLoad: false,
  }
) => {
  const position = useRef(getScrollPosition());
  let throttleTimeout = null;

  const callback = () => {
    const currPos = getScrollPosition();

    effect({ currPos, prevPos: position.current });

    position.current = currPos;
    throttleTimeout = null;
  };

  useIsomorphicLayoutEffect(() => {
    if (!enabled) {
      return;
    }

    if (runOnPageLoad) {
      callback();
    }

    const handleScroll = () => {
      if (throttleTimeout === null) {
        throttleTimeout = setTimeout(callback, WAIT);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [enabled, ...deps]);
};

export default useScrollPosition;
