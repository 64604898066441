import PropTypes from 'prop-types';
import { cloneElement, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

const SHAPE = {
  text: 'text',
  number: 'number',
  dot: 'dot',
};

export const TYPES = [
  'neutral',
  'error',
  'info',
  'primary',
  'success',
  'warning',
  'highlight',
  'secondary',
];

const SHAPE_TYPES = {
  text: 'min-w-fit max-w-fit cursor-default rounded-2 border px-4 py-1 text-xs md:text-sm gap-2',
  number: 'min-w-4 h-4 rounded-full text-xs px-1 max-w-fit',
  dot: 'size-2 rounded-full',
};

const iconsSizeMap = {
  size1: 'size3',
  size2: 'size3',
  size3: 'size3',
  size4: 'size4',
};

const typeStyles = {
  neutral:
    'bg-neutral-high-500 border-neutral-high-500 text-neutral-high-100 [&>svg]:text-neutral-high-100',
  error: 'bg-error-500 border-error-500 text-error-100 [&>svg]:text-error-100',
  info: 'bg-info-500 border-info-500 text-info-100 [&>svg]:text-info-100',
  primary:
    'bg-primary-300 border-primary-300 text-neutral-high-100 [&>svg]:text-neutral-high-100',
  success:
    'bg-success-400 border-success-400 text-success-100 [&>svg]:text-success-100',
  warning:
    'bg-warning-500 border-warning-500 text-warning-100 [&>svg]:text-warning-100',
  highlight:
    'bg-highlight-300 border-highlight-300 text-neutral-high-100 [&>svg]:text-neutral-high-100',
  secondary:
    'bg-secondary-300 border-secondary-300 text-neutral-high-100 [&>svg]:text-neutral-high-100',
};

const Badge = forwardRef(
  (
    {
      className,
      children,
      type = 'neutral',
      shape = 'text',
      iconLeft = null,
      iconRight = null,
      size = 'size3',
      ...rest
    },
    ref
  ) => (
    <span
      ref={ref}
      className={twMerge(
        'flex items-center justify-center gap-2 font-regular',
        SHAPE_TYPES[shape],
        typeStyles[type] ?? typeStyles.neutral,
        className
      )}
      {...rest}
    >
      <>
        {iconLeft &&
          shape === SHAPE.text &&
          cloneElement(iconLeft, {
            size: iconsSizeMap[size],
            className: 'flex',
            'aria-hidden': true,
          })}
        {shape !== SHAPE.dot ? children : null}
        {iconRight &&
          shape === SHAPE.text &&
          cloneElement(iconRight, {
            size: iconsSizeMap[size],
            className: 'flex',
            'aria-hidden': true,
          })}
      </>
    </span>
  )
);

Badge.displayName = 'Badge';

Badge.propTypes = {
  /**
   * String to be rendered inside component
   */
  children: PropTypes.node,
  /**
   * Disable default text uppercase transformation
   */
  uppercase: PropTypes.bool,
  /**
   * Renders badge with one of the properties below
   */
  type: PropTypes.oneOf(TYPES),
  /**
   * Renders badge with one of the shapes below
   */
  shape: PropTypes.oneOf(Object.keys(SHAPE_TYPES)),
  /**
   * Renders an icon on the left side. Available only when using type `text`.
   */
  iconLeft: PropTypes.object,
  /**
   * Renders an icon on the right side. Available only when using type `text`.
   */
  iconRight: PropTypes.object,
};

export default Badge;
