import PropTypes from 'prop-types';
import { forwardRef, useRef } from 'react';
import { useUID } from 'react-uid';
import { twJoin } from 'tailwind-merge';

import useKeyDown from 'hooks/useKeyDown';

import { composeRefs } from 'lib/utils';

import Icon from '../Icon';

const SIZES = {
  size1: 'gap-2 h-10 p-4',
  size2: 'gap-2 h-8 px-4 py-2',
  size3: 'gap-2 h-7 px-4 py-2',
};

const INPUT_SIZES = {
  size1: 'text-base',
  size2: 'text-sm',
  size3: 'text-xs',
};

const SearchInput = forwardRef(
  (
    {
      className,
      disabled = false,
      full = false,
      id: customId,
      name,
      placeholder,
      size = 'size1',
      value,
      onChange,
      onCleanup,
      onBlur,
      onFocus,
      onKeyDown,
      onKeyUp,
      onSend,
    },
    ref
  ) => {
    const wrapperRef = useRef(null);
    const inputRef = useRef(null);
    const uid = useUID();

    const id = customId || uid;

    useKeyDown(
      ['Enter', 'NumpadEnter'],
      (event) => {
        inputRef.current.blur();
        onSend(event);
      },
      {
        enabled: !!onSend,
        targetRef: wrapperRef,
      }
    );

    useKeyDown('Escape', () => {
      inputRef.current.blur();
    });

    const onCleanupHandler = () => {
      onCleanup();
      inputRef.current.focus();
    };

    return (
      <div
        ref={composeRefs(ref, wrapperRef)}
        className={twJoin(
          'relative flex w-fit items-center rounded-full border border-solid focus-within:border-primary-300 dark:bg-neutral-low-400',
          disabled
            ? 'cursor-not-allowed select-none border-neutral-high-300 bg-neutral-high-200 *:cursor-not-allowed *:select-none dark:border-neutral-high-500'
            : 'border-neutral-high-300 bg-neutral-high-100',
          full && 'w-full',
          SIZES[size],
          className
        )}
      >
        <Icon
          className={twJoin(
            'text-neutral-high-400',
            disabled
              ? 'dark:text-neutral-high-500'
              : 'dark:text-neutral-high-200'
          )}
          name="search"
          size={size === 'size3' ? 'size3' : 'size1'}
        />
        <input
          ref={inputRef}
          autoComplete="off"
          className={twJoin(
            'text-ellipsis p-0 focus:ring-0',
            disabled
              ? 'text-neutral-high-500 placeholder:text-neutral-high-500'
              : 'text-neutral-low-400 placeholder:text-neutral-high-500 dark:text-neutral-high-100 dark:placeholder:text-neutral-high-200',
            full && 'w-full',
            INPUT_SIZES[size]
          )}
          disabled={disabled}
          id={id}
          name={name}
          placeholder={placeholder}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
        />
        {!!value && !disabled && (
          <Icon
            aria-label="limpar campo de busca"
            as="button"
            className={
              'absolute right-4 top-1/2 -translate-y-1/2 text-neutral-low-400 dark:text-neutral-high-400'
            }
            name="close-filled"
            size="size3"
            onClick={onCleanupHandler}
          />
        )}
      </div>
    );
  }
);

SearchInput.displayName = 'SearchInput';

SearchInput.propTypes = {
  /**
   * Whether the input field is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Renders input as a full width
   */
  full: PropTypes.bool,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Custom identifier for the input
   */
  id: PropTypes.string,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Receives HTML attr `name` value
   */
  name: PropTypes.string,
  /**
   * Text that appears in the form control when the it is empty
   */
  placeholder: PropTypes.string.isRequired,
  /**
   * Renders component with one of the pre-determined size
   */
  size: PropTypes.string.isRequired,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Value of the input
   */
  value: PropTypes.string.isRequired,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Callback fn to be executed on `onChange` event
   */
  onChange: PropTypes.func.isRequired,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Callback fn to be executed when cleanup button is clicked
   */
  onCleanup: PropTypes.func.isRequired,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Callback fn to be executed on `onBlur` event
   */
  onBlur: PropTypes.func,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Callback fn to be executed on `onFocus` event
   */
  onFocus: PropTypes.func,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Callback fn to be executed on `onKeyDown` event
   */
  onKeyDown: PropTypes.func,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Callback fn to be executed on `onKeyUp` event
   */
  onKeyUp: PropTypes.func,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Callback fn to be executed on `Enter` or `NumpadEnter` event
   */
  onSend: PropTypes.func,
};

export default SearchInput;
