export const SORT_LABELS = {
  BY_LATEST: 'Mais recentes',
  BY_MOST_LIKED: 'Mais curtidas',
  BY_MOST_COMMENTED: 'Mais comentadas',
};

export const SORT_VALUES = {
  LATEST: 'latest',
  MOST_REACTED: 'likes',
  MOST_COMMENTED: 'comments',
};
